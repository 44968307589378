<template>
    <div
        class="home-card-container"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
    >
        <div class="home-card-title font-Headline-4">{{ title }}</div>
        <video
            :id="gifKey"
            class="home-card-gif"
            :src="src"
            muted
            :controls="false"
            disablepictureinpicture
            @loadedmetadata="videoLoadedmetadata"
        ></video>
        <div class="home-card-text font-body-2">{{ text }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: "HomeChooseCard",
    props: {
        gifKey: {
            default: "",
            type: String
        },
        src: {
            default: "",
            type: String,
        },
        title: {
            default: "",
            type: String,
        },
        text: {
            default: "",
            type: String,
        },
        videoOptions: {
            default: {
                playbackRate: 1,    // 播放速度
                startTime: 0,       // 视频开始有短暂的静止时间，设定开始的时间跳过等待
                pauseTime: 0,       // hover时暂停的时间
                restartTime: 0,     // 鼠标离开后开始播放的时间
            }
        }
    },
    data() {

        return {
            playing: false,
            leaving: false,
            videoTimeout: null as any,
        }
    },
    // mounted() {
    //     let videoEl: any = document.getElementById(this.gifKey);
    //     videoEl.addEventListener('ended', this.videoEnded);
    // },
    // beforeUnmount() {
    //     let videoEl: any = document.getElementById(this.gifKey);
    //     videoEl.removeEventListener('ended', this.videoEnded);
    // },
    methods: {
        videoLoadedmetadata() {
            // console.log("loadedmetadata");
            let videoEl: any = document.getElementById(this.gifKey);
            videoEl.addEventListener('seeked', this.videoSeeked);
        },
        videoSeeked() {
            // console.log("seeked");
            let videoEl: any = document.getElementById(this.gifKey);
            if (videoEl.poster) {
                videoEl.removeEventListener('seeked', this.videoSeeked);
                return
            }
            let canvas = document.createElement('canvas');
            canvas.width = videoEl.videoWidth;
            canvas.height = videoEl.videoHeight;
            let ctx: any = canvas.getContext('2d');
            ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
            let dataURL = canvas.toDataURL('image/png');
            videoEl.poster = dataURL;
        },
        videoReload() {
            let videoEl: any = document.getElementById(this.gifKey);
            videoEl.src = '';
            videoEl.src = this.src;
        },
        handleMouseEnter() {
            // if (this.playing) return;
            // this.playing = true;
            let videoEl: any = document.getElementById(this.gifKey);
            if (videoEl.poster) {
                // 为避免第一次加载出现闪屏，
                this.videoReload();
            }
            videoEl.playbackRate = this.videoOptions.playbackRate;
            videoEl.currentTime = this.videoOptions.startTime;
            videoEl.play();

            if (this.videoTimeout) { clearTimeout(this.videoTimeout) }
            this.videoTimeout = setTimeout(() => {
                videoEl.pause();
            }, this.videoOptions.pauseTime * 1000);

            // console.log("enter", videoEl, videoEl.currentTime, typeof(videoEl.currentTime));
        },
        handleMouseLeave() {
            this.playing = false;
            // if (this.leaving) return;
            if (this.videoTimeout) { clearTimeout(this.videoTimeout) }
            let videoEl: any = document.getElementById(this.gifKey);
            videoEl.playbackRate = this.videoOptions.playbackRate;
            videoEl.currentTime = this.videoOptions.restartTime;
            videoEl.play();
            // console.log("Leave");
        },
    }
})
</script>

<style lang="scss" src="./style/homeChooseCard.scss" scoped />

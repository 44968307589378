<template>
    <div class="common-main existing-main">
        <div class="common-background-top transportation-background-top backImgStyle">
            <div class="common-title">
                Existing building
            </div>
        </div>

        <div class="common-block-image existing-img-bg-1 backImgStyle" id="existing-img-bg-/BuildingEquipmentAndFacilitiesOperation">
            <div class="common-block-image-text">
                Building Equipment And Facilities Operation And Maintenance Management System
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Smart Building Operations
            </div>
            <div class="common-part-content">
                Our Building Automation System (BAS) is a vital component of low-voltage systems, monitoring and conserving energy across the building's electromechanical equipment. , enabling property management to leverage modern technology for effective daily operations and sustainable building use.
            </div>
        </div>
        <div class="common-block-container existing-part-padding-220">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Efficient Operation with Building Automation System
                    </div>
                </div>
                <div class="common-info-box-text">
                    The Building Equipment and Facilities Operation and Maintenance Management System's automation component monitors electromechanical equipment throughout the building, emphasizing energy savings through scientific control logic, resulting in effective and efficient management.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Unlocking Potential with Intelligent Building Solutions
                    </div>
                </div>
                <div class="common-info-box-text">
                    Intelligent buildings represent the pinnacle of contemporary high-tech integration, enhancing functions, management levels, safety, and environmental comfort. Their impact on building use effectiveness and owner return on investment spans decades, making them a crucial facet of modern construction.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Focused Management through Automation Platform
                    </div>
                </div>
                <div class="common-info-box-text">
                    The Building Automation Management Platform prioritizes monitoring, control, and adjustment of working conditions and energy consumption. Its emphasis on closed-loop scientific operation and maintenance, including inspection, scheduling, and work order management, ensures precise control over building systems.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">04</div>
                    <div class="common-info-box-title">
                        Enhancing Efficiency with Scientific Management
                    </div>
                </div>
                <div class="common-info-box-text">
                    Establishing the system facilitates scientific management for property units, harnessing modern technology to meet daily office building needs while reducing energy consumption. This aligns with contemporary practices to optimize efficiency and sustainability in building operations.
                </div>
            </div>
        </div>

        <div class="common-block-image existing-img-bg-2 backImgStyle" id="existing-img-bg-/IntegratedOperationsAndManagementPlatform">
            <div class="common-block-image-text">
                Integrated Operations And Management Platform
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Seamless Integration, Enhanced Efficiency
            </div>
            <div class="common-part-content">
                Wove Technologies plays a pivotal role in optimizing the Integrated Operations and Management Platform, ensuring seamless integration and enhanced functionality. By leveraging advanced technologies, Wove contributes to a more efficient and interconnected ecosystem.
            </div>
        </div>
        <div class="common-block-container existing-part-padding-220">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Comprehensive Integration
                    </div>
                </div>
                <div class="common-info-box-text">
                    The platform integrates various systems, including 3D building visualization, IoT, IBMS, FM, and IOC management.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Digital Twin
                    </div>
                </div>
                <div class="common-info-box-text">
                    The system establishes a bidirectional digital twin by correlating operational data from different subsystems in a three-dimensional space.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Business Process Integration
                    </div>
                </div>
                <div class="common-info-box-text">
                    Driven by efficient business processes, the platform ensures a seamless and unified approach to management.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">04</div>
                    <div class="common-info-box-title">
                        Process-Oriented Tools
                    </div>
                </div>
                <div class="common-info-box-text">
                    Utilizing process-oriented management tools, the system aids in establishing and refining information-based, standardized, and regulated management mechanisms.
                </div>
            </div>
        </div>
        <div class="common-part existing-part-padding-160 existing-margin-bottom-200">
            <div class="common-part-content">
                Wove's expertise in digital solutions enhances the platform's capabilities, providing users with a sophisticated and streamlined experience in managing building operations. Through Wove's contributions, the platform achieves greater precision, responsiveness, and adaptability in meeting the diverse needs of modern facilities.
            </div>
        </div>

        <div class="common-block-image existing-img-bg-3 backImgStyle" id="existing-img-bg-/SmartPropertyManagementPlatform">
            <div class="common-block-image-text">
                Smart Property Management Platform
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                A New Era in Comprehensive Management
            </div>
            <div class="common-part-content">
                Wove's Smart Park Business Management Platform encompasses six powerful subsystems, redefining how parks are managed and enhancing the overall park experience. From intelligent property management to comprehensive service platforms, centralized monitoring through IoT, and leveraging big data, Wove Technologies is at the forefront of shaping a smart, tech-driven era for parks.
            </div>
        </div>
        <div class="common-block-container existing-part-padding-220">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Park Style - Smart Park Comprehensive Portal
                    </div>
                </div>
                <div class="common-info-box-text">
                    External information portal for the park, serving information commissioners and acting as a CRM system for investment promotion.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Park Operation - Smart Property Management System
                    </div>
                </div>
                <div class="common-info-box-text">
                    Through Mobile, Visual, Centralized, Standardized, and Internet-based, smart management of park properties is achieved. This aims to enhance user satisfaction, reduce park management costs, improve park management efficiency, and realize paperless park management work.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Park Life Butler - Park O2O Comprehensive Service Platform
                    </div>
                </div>
                <div class="common-info-box-text">
                    A comprehensive service platform for park enterprises and employees, providing "butler-style" services from recruitment to business travel.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">04</div>
                    <div class="common-info-box-title">
                        Park IoT Butler - Centralized Monitoring Platform for Park Equipment
                    </div>
                </div>
                <div class="common-info-box-text">
                    Integrates various intelligent systems/devices within the park using IoT, providing centralized monitoring for equipment operation.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">05</div>
                    <div class="common-info-box-title">
                        Park Big Data Center
                    </div>
                </div>
                <div class="common-info-box-text">
                    Establishes a big data center for the park, collecting and analyzing investment data, operational data, and production data.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">06</div>
                    <div class="common-info-box-title">
                        Park Smart Application Collection
                    </div>
                </div>
                <div class="common-info-box-text">
                    Applies mature technologies (facial recognition, AI, electronic tags) to enhance the technological content of the park.
                </div>
            </div>
        </div>
        <div class="common-part existing-part-padding-160 existing-margin-bottom-200">
            <div class="common-part-content">
                With a firm focus on daily operational needs, the established system not only streamlines scientific management for property units but also harnesses modern technology for sustainable and efficient building operations, reducing energy consumption in the process.
            </div>
        </div>

        <div class="common-block-image existing-img-bg-4 backImgStyle" id="existing-img-bg-/SmartDetentionCenterManagementPlatform">
            <div class="common-block-image-text">
                Smart Detention centers Comprehensive Management Platform
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                The Future of Secure Facility Management
            </div>
            <div class="common-part-content">
                This comprehensive smart management system revolutionizes the traditional approaches to security and operational efficiency in detention centers. To achieve unified management across regions on a large scale, the system needs to accommodate the connection of a massive number of devices. In addition to a modular and layered design, the system requires standardized interfaces to allow seamless integration of video data with upper and lower-level platforms. This facilitates easy integration to realize the overall system.
            </div>
        </div>
        <div class="common-block-container existing-part-padding-220">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Intelligent Management
                    </div>
                </div>
                <div class="common-info-box-text">
                    The intelligentization of various management and control facilities, such as image and sound collection and processing, detection alarms, and access control systems, is realized through centralized management, centralized reception, and intelligent control in supervision work.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Efficient Workflow
                    </div>
                </div>
                <div class="common-info-box-text">
                    Quick information collection, processing, and exchange enable units and individuals to swiftly access needed information. This further enhances efficiency in work, communication, decision-making, and contributes to cost savings in manpower, resources, and energy.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Security Management
                    </div>
                </div>
                <div class="common-info-box-text">
                    Global surveillance and control are established using information technology to ensure the safety and stability of the detention centers. This includes alarm systems, intelligent behavior analysis, and biometric technology to enhance accuracy and prevent oversights.
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">04</div>
                    <div class="common-info-box-title">
                        Solution and Construction
                    </div>
                </div>
                <div class="common-info-box-text">
                    Unified alarm processing, video surveillance system, perimeter alarm system, A/B door system, RFID personnel management system, patrol system, fire protection system, inmate call system, heating and air conditioning, water supply and drainage, power distribution, smart lighting, elevators, energy monitoring, and machine room monitoring system.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container existing-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br/>
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactUsButton from '@/components/contactUsButton.vue'
export default defineComponent({
    name: "ExistingBuilding",
    components: {
        ContactUsButton,
    },
    data() {
        return {}
    },
    created() {},
    mounted() {
        setTimeout(() => {
            // this.tagSwitch(this.$route.query.index);
            this.tagSwitch(this.$route?.path || '/existingBuilding');
        })
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'});
        },
        tagSwitch(path) {
            if (!path || path === '/existingBuilding') return;
            let element = document.getElementById(`existing-img-bg-${path}`);
            let scrollTop = element.getBoundingClientRect().top;
            window.scrollTo({ top: scrollTop - 80, behavior: 'smooth' });
        },
    }
})
</script>

<style lang="scss" src="../style/existingBuilding.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
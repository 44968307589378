<template>
    <div>
        <Header style="z-index: 2" />
        <BackgroundImage />
        <div id="woveApp">
            <el-main style="padding: 0; margin-top: 80px; position: relative; z-index: 1; overflow: hidden">
                <router-view/>
            </el-main>
        </div>
        <Footer />
    </div>
</template>
<script lang="ts">
import Header from '@/views/header/index.vue';
import Footer from '@/views/footer/index.vue';
import BackgroundImage from '@/components/backgroundImage.vue';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        BackgroundImage,
    }
}
</script>

<style lang="scss">
#woveApp {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
     text-align: center;
    //opacity: 0 !important;
}

</style>
<style lang="scss" src="./views/style/fontStyle.scss" />
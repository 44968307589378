<template>
    <div class="app-header">
        <div
            @click="() => { this.mobileMenuActive = false }"
            :class="{
            'app-header-mobile-menu-mask': true,
            'active': mobileMenuActive,
        }"></div>
        <div :class="{
            'app-header-mobile-menu': true,
            'active': mobileMenuActive,
            'font-Headline-4': true,
        }">
            <a
                href="/"
                @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                class="app-header-mobile-menu-item">
                Home
            </a>
            <a
                href="/about"
                @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                class="app-header-mobile-menu-item">
                About Us
            </a>

            <div>
                <div class="app-header-mobile-menu-item app-header-mobile-menu-item-title">
                    Infrastructure
                </div>
                <a
                    href="/infrastructure"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Transportation
                </a>
            </div>

            <div>
                <div class="app-header-mobile-menu-item app-header-mobile-menu-item-title">
                    New Building
                </div>
                <a
                    href="/governmentAndEnterpriseOffice"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Government and Enterprise Office
                </a>
                <a
                    href="/education"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Education
                </a>
                <a
                    href="/retail"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Retail
                </a>
                <a
                    href="/healthcare"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Healthcare
                </a>
                <a
                    href="/hospitality"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Hospitality
                </a>
            </div>

            <div>
                <div class="app-header-mobile-menu-item app-header-mobile-menu-item-title">
                    Existing Building
                </div>
                <a
                    href="/BuildingEquipmentAndFacilitiesOperation"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Building Equipment and Facilities Operation
                </a>
                <a
                    href="/IntegratedOperationsAndManagementPlatform"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Integrated Operations and Management Platform
                </a>
                <a
                    href="/SmartPropertyManagementPlatform"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Smart Property Management Platform
                </a>
                <a
                    href="/SmartDetentionCenterManagementPlatform"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Smart Detention center Management Platform
                </a>
            </div>
            <div>
                <div class="app-header-mobile-menu-item app-header-mobile-menu-item-title">
                    Solutions
                </div>
                <a
                    href="/iot"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    IoT
                </a>
                <a
                    href="/software"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Software
                </a>
                <a
                    href="/hardware"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Hardware
                </a>
                <a
                    href="/meter"
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    class="app-header-mobile-menu-item app-header-mobile-menu-item-child">
                    Intelligent Watt-Hour Meter
                </a>
            </div>

        </div>
        <div class="app-header-box">
            <div
                @mouseover="() => { this.active = 'Building Development' }"
                @mouseleave="() => { this.active = '' }"
                :class="{
                'header-menu-container': true,
                'header-menu-building': true,
                 'active': active === 'Building Development'
            }">
                <div class="header-menu-box">
                    <a class="header-menu-title header-menu-item pointer" href="/infrastructure">Infrastructure</a>
                    <div class="header-menu">
                        <a class="header-menu-item pointer" href="/infrastructure">Transportation</a>
                    </div>
                </div>
                <div class="header-menu-box header-menu-line">
                    <a
                        href="/governmentAndEnterpriseOffice"
                        class="header-menu-title header-menu-item pointer"
                    >New Building</a>
                    <div class="header-menu">
                        <a class="header-menu-item pointer" href="/governmentAndEnterpriseOffice" @click="linkTo('/governmentAndEnterpriseOffice', {})">Government and Enterprise Office</a>
                        <a class="header-menu-item pointer" href="/education" @click="linkTo('/education', {})">Education</a>
                        <a class="header-menu-item pointer" href="/retail" @click="linkTo('/retail', {})">Retail</a>
                        <a class="header-menu-item pointer" href="/healthcare" @click="linkTo('/healthcare', {})">Healthcare</a>
                        <a class="header-menu-item pointer" href="/hospitality" @click="linkTo('/hospitality', {})">Hospitality</a>
                    </div>
                </div>
                <div class="header-menu-box">
                    <a
                        href="/existingBuilding"
                        class="header-menu-title header-menu-item pointer"
                    >Existing Building</a>
                    <div class="header-menu">
                        <a class="header-menu-item pointer" href="/BuildingEquipmentAndFacilitiesOperation" @click="linkTo('/BuildingEquipmentAndFacilitiesOperation', {})">Building Equipment and Facilities<br/>Operation</a>
                        <a class="header-menu-item pointer" href="/IntegratedOperationsAndManagementPlatform" @click="linkTo('/IntegratedOperationsAndManagementPlatform', {})">Integrated Operations and Management<br/>Platform</a>
                        <a class="header-menu-item pointer" href="/SmartPropertyManagementPlatform" @click="linkTo('/SmartPropertyManagementPlatform', {})">Smart Property Management Platform</a>
                        <a class="header-menu-item pointer" href="/SmartDetentionCenterManagementPlatform" @click="linkTo('/SmartDetentionCenterManagementPlatform', {})">Smart Detention center Management<br/>Platform</a>
                    </div>
                </div>
            </div>
            <div
                @mouseover="() => { this.active = 'Solutions' }"
                @mouseleave="() => { this.active = '' }"
                :class="{
                    'header-menu-container': true,
                    'header-menu-solutions': true,
                    'box-solutions': true,
                     'active': active === 'Solutions'
                }">
                <div class="header-menu-box header-menu-flex-column-center">
                    <a class="header-menu-title header-menu-item pointer" href="/iot">IoT</a>
                    <a class="header-menu-title header-menu-item pointer" href="/software">Software</a>
                    <a class="header-menu-title header-menu-item pointer" href="/hardware">Hardware</a>
                    <a class="header-menu-title header-menu-item pointer" href="/meter">Intelligent Watt-Hour Meter</a>
                </div>
            </div>

            <!--Desktop-start==============================================================-->
            <div class="header-container-Desktop header-container"
                 @mouseleave="() => { this.active = ''; }"
            >
<!--                <img src="../../assets/header_logo.webp" class="header-logo">-->
                <a href="/" @mouseenter="() => { this.active = ''; }">
                    <img v-if="isSafiri" src="../../assets/WoveLogo_Tablet.svg" class="header-logo">
                    <video
                        v-if="!isSafiri"
                        id="header_logo_gif"
                        class="header-logo-gif"
                        :poster="require('../../assets/WoveLogo_Tablet.svg')"
                        :src="require('../../assets/gif/Wove logo animation.webm')"
                        @mouseenter="handleMouseEnterLogo"
                        muted :controls="false" disablepictureinpicture></video>
                </a>
                <div class="header-content">
                    <a
                        href="/"
                        @mouseenter="() => { this.active = ''; }"
                        class="header-content-text">Home</a>
                    <a
                        href="/about"
                        @mouseenter="() => { this.active = ''; }"
                        class="header-content-text">About Us</a>
                    <div
                        @mouseover="() => { this.active = 'Building Development' }"
                        @click="() => { this.active = 'Building Development' }"
                        :class="{
                            'header-content-text': true,
                            'active': active === 'Building Development'
                        }"
                    >
                        Building Development
                        <img src="../../assets/Arrow.webp"  class="header-content-arrow">

                    </div>
                    <div
                        @mouseover="() => { this.active = 'Solutions' }"
                        @click="() => { this.active = 'Solutions' }"
                        :class="{
                            'header-content-text': true,
                            'active': active === 'Solutions'
                        }"
                    >
                        Solutions
                        <img src="../../assets/Arrow.webp"  class="header-content-arrow">
                    </div>
                </div>
<!--                <div class="header-contact">Contact Us</div>-->
                <div style="width: 138px" @mouseenter="() => { this.active = ''; }">
                    <ContactUsButton
                        classNames="header-contact"
                        @linkTo="linkTo"
                        :routeInfo="{ path:'/contactUs' }"
                    />
                </div>

            </div>
            <!--Desktop-end==============================================================-->

            <!--Tablet-start==============================================================-->
            <div class="header-container-Tablet header-container">
                <img
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    src="../../assets/Frame%201672.webp"
                    class="header-logo-more pointer">

                <a href="/">
                    <img src="../../assets/WoveLogo_Tablet.svg" class="header-logo-Tablet">
                </a>

                <ContactUsButton
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    classNames="header-contact-Tablet"
                    @linkTo="linkTo"
                    :routeInfo="{ path:'/contactUs' }"
                />
            </div>
            <!--Tablet-end==============================================================-->

            <!--Mobile-start==============================================================-->
            <div class="header-container-Mobile header-container">
                <img
                    @click="() => { this.mobileMenuActive = !this.mobileMenuActive; }"
                    src="../../assets/Frame%201672.webp" class="header-logo-more pointer">
                <a href="/">
                    <img
                        src="../../assets/WoveLogo_Tablet.svg"
                        class="header-logo-Mobile">
                </a>
                <div></div>
            </div>
            <!--Mobile-end==============================================================-->

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ContactUsButton from "@/components/contactUsButton.vue";
// import GifPlayer from "@/components/GifPlayer.vue";
// import "@/utils/gif-player.es6.js"
export default defineComponent ({
    name: "Header",
    components: {
        ContactUsButton,
        // GifPlayer,
    },
    data() {
        return {
            active: '',
            mobileMenuActive: false,
            playing: true,
            isSafiri: navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1,
        }
    },
    mounted() {
        let videoEl: any = document.getElementById('header_logo_gif');
        if (videoEl) {
          this.playing = false;
          this.handleMouseEnterLogo();
        }
        // videoEl.addEventListener('ended', this.videoEnded)
    },
    beforeUnmount() {
        // let videoEl: any = document.getElementById('header_logo_gif');
        // videoEl.removeEventListener('ended', this.videoEnded)
    },

    methods: {
        videoEnded() {
            let videoEl: any = document.getElementById('header_logo_gif');
            videoEl.src = '';
            videoEl.src = require('../../assets/gif/Wove logo animation.webm');
        },
        handleMouseEnterLogo() {
            if (this.playing) return;
            this.playing = true;

            let videoEl: any = document.getElementById('header_logo_gif');
            videoEl.currentTime = 0;
            videoEl.play();
            setTimeout(() => {
                this.playing = false;
                videoEl.currentTime = 2000;
                videoEl.pause();
            }, 2100);
        },
        linkTo(url: any, query: any = {}) {
            let toUrl = typeof url === 'string' ? url : '/contactUs';
            let curPath = this.$route.path;
            if (curPath === url) {
                this.$router.push({ path: toUrl, query: query });
                setTimeout(() => {
                    window.location.reload();
                });
            } else {
                // this.$router.push({ path: toUrl, query: query });
            }
        },
        toContactView() {
            this.$router.push({ path: '/contactUs' })
        },
    }
})
</script>

<style lang="scss" src="./index.scss" scoped />

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/button_left.webp'
import _imports_1 from '../assets/button_right.webp'


const _withScopeId = n => (_pushScopeId("data-v-cd0fa4bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "scroll-block-button-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({
            'scroll-block-main': true,
            'scroll-x': _ctx.canScroll,
        }),
      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEnter && _ctx.handleEnter(...args))),
      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleLeave && _ctx.handleLeave(...args)))
    }, [
      _createElementVNode("div", {
        class: "scroll-block-content",
        id: _ctx.scrollId,
        style: {"transform":"translate(0, 0)"}
      }, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ], 8, _hoisted_1)
    ], 34),
    (_ctx.showButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "scroll-block-button pointer",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleIndustriesButtonClick('left')))
          }),
          _createElementVNode("img", {
            src: _imports_1,
            class: "scroll-block-button pointer tMgl30",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleIndustriesButtonClick('right')))
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
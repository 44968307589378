<template>
    <div class="common-main">
        <div class="common-page-content">
            <div class="common-sub-title">
                Enhances Profitability And Elevates Customer Engagement
            </div>

            <div class="common-part">
                <div class="common-part-title">
                    Distinguish Your Business
                </div>
                <div class="common-part-content">
                    Rise Above the Competition with Wove Technologies, from intelligent displays to streamlined operations, our comprehensive suite of software and hardware solutions enhances customer engagement, optimizes workflows, and drives success in the competitive retail environment.
                </div>
            </div>

            <div class="common-block-container">
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">01</div>
                        <div class="common-info-box-title">
                            real-time product promotion information
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Integrated with the CMS information publishing system, our solution dynamically presents real-time product promotions, showcases new products through engaging carousels, delivers brand promotional videos, and more—all seamlessly facilitated through the RS232 protocol.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">02</div>
                        <div class="common-info-box-title">
                            remote scheduled power on/off
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Our Philips commercial Displays video wall supports remote scheduled power on/off, ensuring safe usage while reducing store operating costs. The video wall comes with a free warranty, and local service centers can address issues within 48 hours, eliminating repair concerns.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">03</div>
                        <div class="common-info-box-title">
                            Effective Promotion
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Promotion is crucial to help consumers understand the differentiated advantages of products compared to competitors. With digital signage, you can shape your brand identity with strategic use of video content, also information such as the price, advantages, highlights, promotional activities of new products, as well as product visuals and promotional materials, can be synchronously released across different storefronts.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">04</div>
                        <div class="common-info-box-title">
                            Cutting Promotional Costs
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Compared to traditional printed posters, pull-up banners, and water cards, it significantly reduces the promotional cost of printing materials for stores.
                    </div>
                </div>
            </div>

            <div class="common-block-left-right">
                <img src="../../assets/retail_img_1.webp" class="common-block-img-desktop">
                <img src="../../assets/retail_img_1.webp" class="common-block-img-tablet">
                <img src="../../assets/retail_img_1.webp" class="common-block-img-mobile">
                <div class="common-block-left-right-info">
                    <div class="common-block-right-info-container">
                        <div class="common-block-left-right-title">
                            Elevate In-Store Engagement with Wove’s Smart Digital Signage Solution
                        </div>
                        <div class="common-block-left-right-text">
                            Effective focus on capturing consumer attention and enhancing the in-store experience is one of the key strategies for boosting store popularity and achieving rapid revenue growth. AOC's Smart Digital Signage Solution, leveraging intelligent, diverse, and convenient information display methods, provides a superior visual experience for in-store consumers.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-block-left-right common-flex-reverse">
                <div class="common-block-left-right-info">
                    <div class="common-block-left-info-container">
                        <div class="common-block-left-right-title">
                            Engaging Practical Training with Touchscreen Interaction
                        </div>
                        <div class="common-block-left-right-text">
                            Engage students with dynamic and persuasive teaching using the touchscreen display's multi-touch writing capabilities. Experience 4K high-definition resolution, finer pixel spacing, and vibrant, lifelike visuals. The tempered glass protection and safety features ensure a secure and flexible learning environment.
                        </div>
                    </div>
                </div>
                <img src="../../assets/retail_img_2.webp" class="common-block-img">
            </div>

            <div class="common-part tMgb160-responsive">
                <div class="common-part-title">
                    Our Technology Drives Digital Transformation in Schools
                </div>
                <div class="common-part-content">
                    Our Technology goes beyond current successes with the above solutions. As we advance digital construction on campuses, we aim to introduce cutting-edge solutions that push boundaries. Our commitment to driving positive transformations in academic collaboration, administrative efficiency, and the overall educational experience remains unwavering.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container retail-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br />
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import ContactUsButton from '@/components/contactUsButton.vue'

export default defineComponent({
    name: "Retail",
    components: {
        ContactUsButton,
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="../style/buildingDevelopment.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
<template>
    <div class="common-main">
        <div class="common-page-content">
            <div class="common-sub-title">We Empowers the People's Government And Enterprise Office</div>

            <div class="common-part">
                <div class="common-part-title">Weave you Future, work with our technology</div>
                <div class="common-part-content">
                    Our solution redefines efficiency for the People's Government and Enterprise Office,
                    seamlessly integrating advanced technologies. From intelligent data management to
                    streamlined communication, it's a catalyst for modern governance and enterprise excellence.
                </div>
            </div>

            <div class="common-block-container">
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">01</div>
                        <div class="common-info-box-title">Streamlining Operations</div>
                    </div>
                    <div class="common-info-box-text">
                        Our solutions enhance operational efficiency by saving manpower and time through centralized control and remote operations for functions like scheduled power on/off and volume adjustments. The integrated Digital Signage + CMS solution facilitates easy access to information, expedites workflows, and boosts overall operational efficiency.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">02</div>
                        <div class="common-info-box-title">Versatile Display Means</div>
                    </div>
                    <div class="common-info-box-text">
                        With built-in splicing processing modules, it enables various display functions such as screen roaming, cross-screen display, zoom in and out, picture-in-picture, picture-outside-picture, and overlay.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">03</div>
                        <div class="common-info-box-title">Optimizing monitor Operation - CMS system</div>
                    </div>
                    <div class="common-info-box-text">
                        Integrated with the CMS information delivery system, We ensure seamless expansion, compatibility with various terminals, and efficient integration with existing client systems. This solution provides real-time supervision and comprehensive big data assessment for integrated sanitation operations. The CMS mobile app allows users to monitor operations, view real-time work situations, and receive alerts, offering convenient access to comprehensive operational insights on-the-go.

                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">04</div>
                        <div class="common-info-box-title">A Versatile Cloud-Based Solution for Rapid Content Push</div>
                    </div>
                    <div class="common-info-box-text">
                        With the ability to seamlessly switch between various formats, including images, videos, live streams, and files, through the cloud, it provides a dynamic and efficient platform for content dissemination.
                    </div>
                </div>
            </div>

            <div class="common-block-left-right">
                <img src="../../assets/government_img_1.webp" class="common-block-img-desktop">
                <img src="../../assets/government_img_1.webp" class="common-block-img-tablet">
                <img src="../../assets/government_img_1.webp" class="common-block-img-mobile">
                <div class="common-block-left-right-info">
                    <div class="common-block-right-info-container">
                        <div class="common-block-left-right-title">
                            Attractive And Clear Conference Displays
                        </div>
                        <div class="common-block-left-right-text">
                            For meeting scenarios, the AOC Intelligent Video Wall with Multiple Monitors effectively replaced traditional meeting curtains, presenting government reports, project updates, and administrative training in high definition.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-block-left-right common-flex-reverse">
                <div class="common-block-left-right-info">
                    <div class="common-block-left-info-container">
                        <div class="common-block-left-right-title">
                            High Definition
                        </div>
                        <div class="common-block-left-right-text">
                            Supporting 4K display quality output and features like 3D noise reduction, it delivers high-definition and detailed images for a realistic display effect.
                        </div>
                    </div>
                </div>
                <img src="../../assets/government_img_2.webp" class="common-block-img">
            </div>

            <div class="common-part tMgb160-responsive">
                <div class="common-part-title">
                    A Seamless Solution
                </div>
                <div class="common-part-content">
                    Our tailored solutions aim to elevate office and government operations by seamlessly integrating systems, efficiently managing resources, and employing advanced technologies for streamlined processes. Wove empowers you to achieve heightened productivity, cost savings, and a more connected working environment.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container government-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br />
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import ContactUsButton from '@/components/contactUsButton.vue'

export default defineComponent({
    name: "GovernmentAndEnterpriseOffice",
    components: {
        ContactUsButton,
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="../style/buildingDevelopment.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
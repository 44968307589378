<template>
    <div
        id="bg"
        ref="bg"
        class="app-background"
        :style="`background-image: url(${imgUrl})`"
    ></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import $ from 'jquery';

// function pageScroll() {
//     let scrollTop = $("html, body").scrollTop();
//     let $bg = $('#bg');
//     console.log('$bg====', $bg, $bg.height())
//     if (!$bg || !$bg.height) return;
//     let bgTop = -((scrollTop || 0) * (($bg.height() * .35) / document.body.scrollHeight));
//     $bg.css("transform", "translate3d(0," + bgTop + "px,0)");
// }
// document.body.onscroll = pageScroll;
// window.onresize = pageScroll;
// pageScroll();

export default defineComponent({
    name: "BackgroundImage",
    data() {
        return {
            imgUrl: require('../assets/AdobeStock_330638885.webp'),
            routers: [
                {
                    path: '/contactUs',
                    imgUrl: '../assets/ContactUs_bg.webp'
                },
                {
                    path: '/about',
                    imgUrl: '../assets/AboutUs_bg.webp'
                },
                {
                    path: '/infrastructure',
                    imgUrl: '../assets/Infrastructure_BG.webp'
                },
                {
                    path: '/buildingDevelopment',
                    imgUrl: '../assets/Government_BG.webp'
                },
                {
                    path: '/existingBuilding',
                    imgUrl: '../assets/Existing_Building_BG.webp'
                },
                {
                    path: '/iot',
                    imgUrl: '../assets/IoT_BG.webp'
                },
                {
                    path: '/hardware',
                    imgUrl: '../assets/Hardware_BG.webp'
                },
                {
                    path: '/software',
                    imgUrl: '../assets/Software_BG.webp'
                },
                {
                    path: '/meter',
                    imgUrl: '../assets/Meter_BG.webp'
                },
                {
                    path: '/',
                    imgUrl: '../assets/AdobeStock_330638885.webp'
                },
            ],
        }
    },
    created() {
        const router = useRouter();
        watch(
            () => router.currentRoute.value, // 监听的表达式，这里是当前路由对象
            (to, from) => {
                // 路由变化时的回调函数
                // console.log('路由发生变化');
                // console.log('当前路由：', to);
                this.initCurImg(to);
                // console.log('上一个路由：', from);
            }
        );
    },
    mounted() {
        document.body.onscroll = this.pageScroll;
        window.onresize = this.pageScroll;
        this.pageScroll();
    },
    methods: {
        pageScroll() {
            let scrollTop = $("html, body").scrollTop();
            let $bg: any  = this.$refs['bg'];
            if (!$bg || !$bg.clientHeight) return;
            let bgTop = -((scrollTop || 0) * (($bg.clientHeight * .35) / document.body.scrollHeight));
            $bg.style.transform = "translate3d(0," + bgTop + "px,0)";
        },
        initCurImg(curRoute: any) {
            // console.log('当前路由：', curRoute);
            let curImgObj = this.routers.find(item => curRoute.fullPath.indexOf(item.path) > -1);
            if (!curImgObj) return;
            // this.imgUrl = require(curImgObj.imgUrl);

            if (curImgObj.path === '/') {
                this.imgUrl = require('../assets/AdobeStock_330638885.webp')
            }
            if (curImgObj.path === '/contactUs') {
                this.imgUrl = require('../assets/ContactUs_bg.webp')
            }
            if (curImgObj.path === '/about') {
                this.imgUrl = require('../assets/AboutUs_bg.webp')
            }
            if (curImgObj.path === '/infrastructure') {
                this.imgUrl = require('../assets/Infrastructure_BG.webp')
            }
            if (curImgObj.path === '/buildingDevelopment') {
                this.imgUrl = require('../assets/Government_BG.webp')
            }
            if (curImgObj.path === '/existingBuilding') {
                this.imgUrl = require('../assets/Existing_Building_BG.webp')
            }
            if (curImgObj.path === '/iot') {
                this.imgUrl = require('../assets/IoT_BG.webp')
            }
            if (curImgObj.path === '/hardware') {
                this.imgUrl = require('../assets/Hardware_BG.webp')
            }
            if (curImgObj.path === '/software') {
                this.imgUrl = require('../assets/Software_BG.webp')
            }
            if (curImgObj.path === '/meter') {
                this.imgUrl = require('../assets/Meter_BG.webp')
            }
            // console.log('this.imgUrl===', this.imgUrl);
            // this.imgUrl = 'https://develop.wovetech.co/img/AdobeStock_330638885.a997918f.webp';

        },
    },
})
</script>

<style lang="scss" scoped>
.app-background {
    position: fixed;
    //height: calc(100vh + 140px);
    height: 150vh;
    width: calc(100vw + 60px);
    //background-image: url(https://develop.wovetech.co/img/AdobeStock_330638885.a997918f.webp);
    background-repeat: no-repeat;
    background-size: cover;
    //background-size: 200%;
    top: -30px;
    left: -30px;
    filter: blur(10px) grayscale(.55) brightness(1.3);
    z-index: -1;
}

</style>
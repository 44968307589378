<template>
    <div class="hardware-scroll-block">
        <div v-for="(info, index) of infoList" class="hardware-scroll-item">
            <img :src="require(`../../assets/Hardware_Monitor_img_${index + 1}.webp`)" class="hardware-scroll-block-img">
            <div class="hardware-scroll-block-name">{{ info.name }}wq</div>
            <ul>
                <li v-for="text of info.infos" class="hardware-scroll-block-info">
                    {{ text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name: "MonitorList",
    data() {
        return {
            infoList: [
                {
                    index: 1,
                    name: 'Philips 559M1RYV',
                    infos: [
                        '4K HDR display with Ambiglow',
                        'Designed for Xbox'
                    ]
                },
                {
                    index: 2,
                    name: 'AOC CQ32G2SE/BK',
                    infos: [
                        '31.5" QHD display with 165 Hz, 1 ms response time',
                        'FreeSync Premium'
                    ]
                },
                {
                    index: 3,
                    name: 'AOC CQ27G3S',
                    infos: [
                        'FreeSync Premium',
                        '27\'\' Curved Gaming Monitor'
                    ]
                },
                {
                    index: 4,
                    name: 'AGON AG273FXR',
                    infos: [
                        'FreeSync Premium Gaming Monitor',
                        '144Hz Refresh Rate'
                    ]
                },
                {
                    index: 5,
                    name: 'AGON PD27',
                    infos: [
                        'Porsche design with QHD resolution',
                        '240Hz refresh rate, 1000R curved display'
                    ]
                },
                {
                    index: 6,
                    name: 'Philips 499P9H',
                    infos: [
                        '49" curved 32:9 SuperWide display',
                        'Pop-up webcam with Windows Hello™'
                    ]
                },
                {
                    index: 7,
                    name: 'AOC 27T1',
                    infos: [
                        '27 inch IPS',
                        'QHD Resolution'
                    ]
                },
                {
                    index: 8,
                    name: 'AGON AG273FZE',
                    infos: [
                        'Navidia G-sync',
                        'AGON III 27" Gaming Monitor'
                    ]
                },
                {
                    index: 9,
                    name: 'AOC 16T2',
                    infos: [
                        '15.6 inch IPS',
                        '10-point Touch Portable Monito'
                    ]
                },
                {
                    index: 10,
                    name: 'Philips 222B9T/00',
                    infos: [
                        'Interactive display with SmoothTouch',
                        'Water and dust resistance surface'
                    ]
                },
                {
                    index: 11,
                    name: 'Philips 272B1G/00',
                    infos: [
                        '27" monitor with super energy efficiency',
                        'PowerSensor and LightSensor'
                    ]
                },
                {
                    index: 12,
                    name: 'AGON AG325QZN/EU',
                    infos: [
                        '31.5” inch QHD gaming monitor',
                        '240Hz refresh rate, super-fast 1ms GtG & 0.5'
                    ]
                },
                {
                    index: 13,
                    name: 'AGON PRO AG276QZD',
                    infos: [
                        '27\'\' QHD OLED gaming monitor with 240Hz refresh rate',
                        'Red Dot Winner 2023'
                    ]
                },
                {
                    index: 14,
                    name: 'Philips 27B1U7903/00',
                    infos: [
                        '4K UHD Mini-LED Thunderbolt™ 4 monitor',
                        '3840 x 2160 (4K UHD)'
                    ]
                },
                {
                    index: 15,
                    name: 'AOC CU34G2X/BK',
                    infos: [
                        'Curved Frameless Immersive Gaming Monitor',
                        'UltraWide QHD 3440x1440'
                    ]
                },
                {
                    index: 16,
                    name: 'Philips Evnia 34M2C8600/00',
                    infos: [
                        'QD OLED curved gaming monitor',
                        '3440 x 1440 (WQHD)'
                    ]
                },
                {
                    index: 17,
                    name: 'AGON PRO PD49',
                    infos: [
                        '49” QD-OLED display',
                        'Lightning-fast 240Hz refresh rate'
                    ]
                },
                {
                    index: 18,
                    name: 'Philips Evnia 32M1C5200W/00',
                    infos: [
                        'Curved Gaming Monitor',
                        '1920 x 1080 (Full HD)'
                    ]
                },
                {
                    index: 19,
                    name: 'AOC Q27P3CW',
                    infos: [
                        '27" 16:9 IPS Panel',
                        'QHD 2560 x 1440 at 75 Hz'
                    ]
                },
                {
                    index: 20,
                    name: 'Philips 49B2U6900CH/00',
                    infos: [
                        '32:9 SuperWide curved monitor with USB-C',
                        '5120 x 1440 (Dual QHD)'
                    ]
                },
                {
                    index: 21,
                    name: 'Philips 49B2U6900CH/00',
                    infos: [
                        'Ultra-Slim Portable Monitor with IPS Panel',
                        'Full HD 1920x1080 Resolution'
                    ]
                },
                {
                    index: 22,
                    name: 'AOC 27B3HM/BK',
                    infos: [
                        '27” Full HD monitor',
                        '3-sided frameless & ultra slim design'
                    ]
                },
                {
                    index: 23,
                    name: 'Philips 275S9JML/00',
                    infos: [
                        'LCD Business Monitor',
                        '2560 x 1440 (QHD)'
                    ]
                },
                {
                    index: 24,
                    name: 'AOC U27P2',
                    infos: [
                        '27” IPS screen with UHD 4K resolution',
                        'Featuring 178°/178° wide viewing angles'
                    ]
                },
            ],
        }
    },
})
</script>

<style lang="scss" scoped></style>
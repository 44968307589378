<template>
    <div class="common-main">
        <div class="common-page-content">
            <div class="common-sub-title">
                Enhance The Development Of A Smart Campus
            </div>

            <div class="common-part">
                <div class="common-part-title">
                    Vision for Educational Excellence
                </div>
                <div class="common-part-content">
                    Wove Technologies pioneers a new era in education, leveraging smart technologies to create an immersive and collaborative learning environment. From advanced classroom technologies to personalized learning experiences, Wove is committed to enhancing educational outcomes through innovative and interconnected solutions.
                </div>
            </div>

            <div class="common-block-container">
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">01</div>
                        <div class="common-info-box-title">
                            Wire-Free Teaching
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        With our conference tablet, teaching and training are not restricted
                        <br/><br/>
                        Our Philips conference tablet adopts an integrated design, incorporating functions such as projectors, electronic whiteboards, and computers into one, eliminating various wiring troubles. All-in-one integration simplifies the process, making teaching training and daily meeting communication at the school more relaxed and efficient.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">02</div>
                        <div class="common-info-box-title">
                            Seamless Remote Discussions
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Our Philips conference tablet enables interactive collaboration in large-scale academic seminars and work meetings by incorporating features like remote screen sharing and projection. Participants across diverse locations can simultaneously annotate and share content on the conference tablet, offering a convenient and efficient communication channel for remote education meetings. This creates a new realm of intelligent education collaboration, facilitating distance-free academic discussions
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">03</div>
                        <div class="common-info-box-title">
                            Diverse Storage
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Take away teaching materials by scanning QR codes.
                        The Philips smart conference tablet allows teachers to take away courseware content by scanning QR codes, and it also offers a one-click sharing feature. The scanned content can be synchronized and saved to mobile phones. It helps teachers improve pre-class preparation efficiency and further enhances the quality of education and teaching.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">04</div>
                        <div class="common-info-box-title">
                            Clear and Efficient Presentation of Courseware
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Our Philips Smart Board guarantees a clear and efficient courseware display. Featuring high-quality stereo speakers, the sound envelops the entire teaching space for clear instruction audibility. The 86-inch premium capacitive screen ensures uninterrupted touch writing, providing a seamless whiteboard experience. It eliminates dust impact, fostering an eco-friendly classroom. With wireless screen mirroring for electronic files and support for gestures and instant saving, the Philips Smart Board facilitates convenient and efficient teaching.
                    </div>
                </div>
            </div>

            <div class="common-block-left-right">
                <img src="../../assets/education_img_1.webp" class="common-block-img-desktop">
                <img src="../../assets/education_img_1.webp" class="common-block-img-tablet">
                <img src="../../assets/education_img_1.webp" class="common-block-img-mobile">
                <div class="common-block-left-right-info">
                    <div class="common-block-right-info-container">
                        <div class="common-block-left-right-title">
                            Captivating Displays in the Auditorium
                        </div>
                        <div class="common-block-left-right-text">
                            AOC LED displays in the auditorium craft a vibrant stage backdrop with high color and brightness uniformity, enhancing visual depth. Adaptability to various lighting conditions ensures optimal performance. Versatile features like image capture and seamless switching add flexibility, creating an immersive experience for students during campus events.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-block-left-right common-flex-reverse">
                <div class="common-block-left-right-info">
                    <div class="common-block-left-info-container">
                        <div class="common-block-left-right-title">
                            Engaging Practical Training with Touchscreen Interaction
                        </div>
                        <div class="common-block-left-right-text">
                            Engage students with dynamic and persuasive teaching using the touchscreen display's multi-touch writing capabilities. Experience 4K high-definition resolution, finer pixel spacing, and vibrant, lifelike visuals. The tempered glass protection and safety features ensure a secure and flexible learning environment.
                        </div>
                    </div>
                </div>
                <img src="../../assets/education_img_2.webp" class="common-block-img">
            </div>

            <div class="common-part tMgb160-responsive">
                <div class="common-part-title">
                    Our Technology Drives Digital Transformation in Schools
                </div>
                <div class="common-part-content">
                    Our Technology goes beyond current successes with the above solutions. As we advance digital construction on campuses, we aim to introduce cutting-edge solutions that push boundaries. Our commitment to driving positive transformations in academic collaboration, administrative efficiency, and the overall educational experience remains unwavering.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container education-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br />
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import ContactUsButton from '@/components/contactUsButton.vue'

export default defineComponent({
    name: "Education",
    components: {
        ContactUsButton,
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="../style/buildingDevelopment.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
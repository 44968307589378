<template>
    <div class="common-main">
        <div class="common-page-content">
            <div class="common-sub-title">
                Enhancing Management Levels And Medical Work Efficiency
            </div>

            <div class="common-part">
                <div class="common-part-title">
                    Revolutionizing Healthcare with Smart Solutions
                </div>
                <div class="common-part-content">
                    Our offerings include intelligent digital signage systems, interactive touchscreen displays, CMS 2.0 information publishing systems, and more. These solutions enable efficient communication, seamless data integration, and personalized patient interactions, contributing to an advanced and patient-centric healthcare environment.
                </div>
            </div>

            <div class="common-block-container">
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">01</div>
                        <div class="common-info-box-title">
                            Touchscreen for Seamless Healthcare Monitoring
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        The large screen integrates touch controls, allowing healthcare professionals to interact effortlessly by simply tapping the screen. This facilitates the retrieval of various instrument data within patient rooms, displaying it synchronously on the 55BDL2051T Smart Touch Screen. This aids doctors in gaining comprehensive patient information, enabling better medical decision-making.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">02</div>
                        <div class="common-info-box-title">
                            Dynamic Medical Messaging
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        With the CMS2.0 Information Publishing System, hospitals can dynamically display relevant medical information based on different time periods. During peak hours, digital signage provides guidance on registration, consultation reminders, and the consultation process, streamlining patient flow and enhancing service desk efficiency. Healthcare professionals can centrally control and manage real-time information across all digital signage, ensuring unified and efficient hospital management.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">03</div>
                        <div class="common-info-box-title">
                            Streamlined Healthcare Communication
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        The introduction of Philips 55BDL2051T Smart Interactive Display transforms the nurse station into a centralized information hub. The interactive screen facilitates real-time data updates, presents patient information in a card-based format, and allows medical staff to easily access scheduling and handover information. This innovative solution streamlines daily workflows for healthcare professionals, truly achieving a "one-screen overview."
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">04</div>
                        <div class="common-info-box-title">
                            24/7 Reliability for healthcare centers
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        With 24/7 low power consumption and stable operation, it comprehensively meets the hospital's requirements for long-term, continuous use of the spliced screen.
                    </div>
                </div>
            </div>

            <div class="common-block-left-right">
                <img src="../../assets/healthcare_img_1.webp" class="common-block-img-desktop">
                <img src="../../assets/healthcare_img_1.webp" class="common-block-img-tablet">
                <img src="../../assets/healthcare_img_1.webp" class="common-block-img-mobile">
                <div class="common-block-left-right-info">
                    <div class="common-block-right-info-container">
                        <div class="common-block-left-right-title">
                            High-Definition Display for Remote Medical Consultation
                        </div>
                        <div class="common-block-left-right-text">
                            Medical teams require professional high-definition display terminals to accurately showcase a large amount of medical information such as ultrasound, pathology, electrocardiography, laboratory tests, and medical records. This aids doctors in remote image reading, research discussions, and other activities, ensuring the accuracy of diagnosis and avoiding misdiagnosis.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-block-left-right common-flex-reverse">
                <div class="common-block-left-right-info">
                    <div class="common-block-left-info-container">
                        <div class="common-block-left-right-title">
                            Connecting Medical Education
                        </div>
                        <div class="common-block-left-right-text">
                            Solution Overview Philips Commercial Display implemented the Video Wall 4988XL series to create a 2x2 spliced giant screen in the meeting room of the hospital. This setup facilitates various functions, including remote medical consultations and medical teaching and training, reshaping the training mode for medical and nursing staff. It also promotes technical and resource exchange between the hospital and external medical institutions, empowering the establishment of a smart medical system.
                        </div>
                    </div>
                </div>
                <img src="../../assets/healthcare_img_2.webp" class="common-block-img">
            </div>

            <div class="common-part tMgb160-responsive">
                <div class="common-part-title">
                    Enhancing management levels and medical work efficiency
                </div>
                <div class="common-part-content">
                    From establishing smart medical systems to facilitating remote consultations and reshaping medical education, we empower healthcare centers to elevate patient care and operational efficiency. Our technology fosters collaboration, enabling seamless resource exchange between hospitals and external institutions. Join us in revolutionizing healthcare through cutting-edge, intelligent solutions.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container healthcare-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br />
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import ContactUsButton from '@/components/contactUsButton.vue'

export default defineComponent({
    name: "Healthcare",
    components: {
        ContactUsButton,
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="../style/buildingDevelopment.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
<template>
    <div class="common-main iot-main">
        <div class="common-background-top transportation-background-top backImgStyle">
            <div class="common-title">
                Internet of Things (IoT)
            </div>
        </div>

        <div class="common-block-image backImgStyle">
            <div class="common-block-image-text" style="color: black">
                Smart IoT Water Leakage Protection
            </div>
        </div>
        <div class="common-block-image iot-img-bg-1 backImgStyle">
            <div class="common-block-image-text">
                Smart Fire Safety IoT Platform
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Revolutionizing Urban Fire Safety with IoT Innovations
            </div>
            <div class="common-part-content">
                Urban Fire Internet of Things (IoT) Platform addresses the escalating fire safety risks in rapidly growing urban environments. As cities witness an increase in high-rise structures, the need for advanced firefighting technologies becomes imperative. This platform aims to integrate technological advancements in firefighting and rescue operations, aligning them with the demands of contemporary urban governance.
            </div>
        </div>
        <div class="common-block-container existing-part-padding-220">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Application Advantages of Smart Property Fire Platform
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Safety Big Data ensures real-time safety monitoring.
                        </li>
                        <li>
                            Supports timely completion of daily inspections for firefighting infrastructure.
                        </li>
                        <li>
                            Contributes to risk reduction through effective fire safety supervision.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Current Status of Property Fire Management
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Manual inspection processes lead to inefficiencies and errors.
                        </li>
                        <li>
                            Difficulty in promptly understanding equipment conditions hampers optimal maintenance planning.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Smart Firefighting
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Utilizes IoT, AI, VR, and other technologies for digitizing firefighting information services.
                        </li>
                        <li>
                            Integration with big data cloud platforms enhances urban firefighting capabilities.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">04</div>
                    <div class="common-info-box-title">
                        Logical Framework Platform Interface
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Provides real-time monitoring of safety hazards.
                        </li>
                        <li>
                            Facilitates daily inspections, meeting firefighting regulations.
                        </li>
                        <li>
                            Manages internal fire safety inspections to reduce risks.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="common-part existing-part-padding-160 existing-margin-bottom-200">
            <div class="common-part-title">
                Enhancing city safety with Wove's technology
            </div>
            <div class="common-part-content">
                As urbanization accelerates, fire prevention and safety assume critical roles in city management. The Smart Fire IoT Platform combines cutting-edge technologies, such as IoT, AI, and big data, to digitize urban firefighting information services. This digitization becomes the foundation for efficient firefighting, disaster relief, and the establishment of dynamic supervision and dispatch systems.
            </div>
        </div>

        <div class="common-block-image iot-img-bg-2 backImgStyle">
            <div class="common-block-image-text">
                Internet of Things (IoT)
            </div>
        </div>
        <el-tabs v-model="IOTActiveName" class="iot-tabs" @tab-click="handleTabClick">
            <el-tab-pane label="Edge Computing Gateway" name="Edge Computing Gateway">
                <div class="iot-tab-content">
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_1_img_1.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Controllers, DDC Devices
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            The CPU integrates no fewer than 4 sets of 10/100M Bit/s RJ45 Ethernet ports, and supports different network segments; Built-in IoT MQTT protocol with 3DES encryption capability.
                        </div>
                    </div>
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_1_img_2.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Programmable Gateway
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Modular expansion is possible with the XC control module, with the ability to expand up to six groups at most; supports C language programming and is open for further development.
                        </div>
                    </div>
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_1_img_3.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            End-point Sensor<br/>
                            End devices for air ducts and water pipes
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Industrial-grade high-precision sensors, suitable for use in electronics factories, million-level laboratories, etc.
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Smart Lighting" name="Smart Lighting">
                <div class="iot-tab-content">
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_2_img_1.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Intelligent Switch Lighting Module
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Can be shared with PORIS building control controllers, control networks, software, etc.
                        </div>
                    </div>
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_2_img_2.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Intelligent Dimming Module
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Can be shared with PORIS building control controllers, control networks, software, etc.
                        </div>
                    </div>
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_2_img_3.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Lighting Panel, Illuminance, Infrared
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Can be freely combined with intelligent lighting modules. Color and style are optional. The back panel can be customized with characters. Switch status and backlight display are available.
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Energy Management" name="Energy Management">
                <div class="iot-tab-content">
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_3_img_1.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Intelligent Switch Lighting Module
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Can be shared with PORIS building control controllers, control networks, software, etc.
                        </div>
                    </div>
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_3_img_2.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Intelligent Dimming Module
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Can be shared with PORIS building control controllers, control networks, software, etc.
                        </div>
                    </div>
                    <div class="iot-tab-content-item">
                        <img src="../../assets/IoT_tab_3_img_3.webp" class="iot-tab-img">
                        <div class="iot-tab-title">
                            Lighting Panel, Illuminance, Infrared
                        </div>
                        <div class="iot-tab-line"></div>
                        <div class="iot-tab-text">
                            Can be freely combined with intelligent lighting modules. Color and style are optional. The back panel can be customized with characters. Switch status and backlight display are available.
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <div class="common-block-image iot-img-bg-3 backImgStyle iot-img-no-brightness">
            <div class="common-block-image-text">
                LoRaWAN® Gateways
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Revolutionizing IoT Connectivity with LoRaWAN® Gateways
            </div>
            <div class="common-part-content">
                Step into a world where IoT connectivity takes a leap forward with our advanced LoRaWAN® Gateways. In today's dynamic landscape, these gateways redefine the essence of staying connected by seamlessly integrating sensor data into your network. Offering unmatched versatility and efficiency, our gateways are crafted for diverse applications, navigating industrial complexities, and thriving in outdoor installations.
            </div>
        </div>
        <div class="common-block-left-right existing-part-padding-160">
            <img src="../../assets/IoT_img_1.webp" class="common-block-img">
            <div class="common-block-left-right-info">
                <div class="common-block-right-info-container">
                    <div class="common-block-left-right-title">
                        Enable all-around connectivity
                    </div>
                    <div class="common-block-left-right-text">
                        <ul>
                            <li>Provide Large Capacity & Faster Data Processing</li>
                            <li>Handle Higher Amount of Traffic with Lower Consumption Handle Massive Sensor Data Efficiently</li>
                            <li>OpenVPN/IPsec/PPTP/L2TP/GRE/DMVPN</li>
                            <li>Embedded Network Server & High Compatibility with Mainstream Networks Including Actility and Senet</li>
                            <li>Support CN470/RU864/IN865/EU868/AU915/ US915/KR920/AS923-1&2&3&4</li>
                            <li>Connecting End Nodes Varies from Class A/B/C</li>
                            <li>Extraordinary Features: Noise Analyzer, Multicast, Gateway Fleet</li>
                            <li>Embedded Node-RED Development Tool (UG56/65/67)</li>
                            <li>Embedded Python SDK (UG56/65/67)</li>
                            <li>Listen Before Talk</li>
                            <li>Support BACnet (UG56/65/67)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="existing-margin-bottom-200"></div>

        <div class="common-block-image iot-img-bg-4 backImgStyle">
            <div class="common-block-image-text">
                Indoor Air Quality Sensor
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Innovative Sensor Solutions for Enhanced Environments
            </div>
            <div class="common-part-content">
                Our advanced sensor solutions are designed to elevate indoor air quality and smart spaces. Tailored for applications such as smart classrooms, hospitals, and supermarkets, our IAQ Sensors offer a glimpse into the unseen, providing valuable insights for a healthier and smarter environment.
            </div>
        </div>
        <div class="common-block-left-right existing-part-padding-160">
            <img src="../../assets/IoT_img_2.webp" class="common-block-img">
            <div class="common-block-left-right-info">
                <div class="common-block-right-info-container">
                    <div class="common-block-left-right-title">
                        Enhancing Security and Efficiency
                    </div>
                    <div class="common-block-left-right-text">
                        <ul>
                            <li>Ensure a secure environment with a traffic light status indicator.</li>
                            <li>Anti-theft protection for added security.</li>
                            <li>Cost-effective bulk deployment for scalable solutions.</li>
                            <li>Experience visual and intuitive data display with a 2.13-inch E-ink screen.</li>
                            <li>Powered via USB Type-C for convenience.</li>
                            <li>Extended battery life for prolonged, uninterrupted usage.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-block-left-right common-flex-reverse existing-part-padding-160">
            <div class="common-block-left-right-info">
                <div class="common-block-left-info-container">
                    <div class="common-block-left-right-title">
                        The Role of IAQ Sensors and IoT Controllers
                    </div>
                    <div class="common-block-left-right-text">
                        In our pursuit of enhanced energy efficiency and well-being, indoor air quality (IAQ) sensors and IoT controllers play a pivotal role. These advanced technologies play a pivotal role in measuring air composition within ventilation units, facilitating the monitoring and adjustment of heating, ventilation, and air conditioning (HVAC) systems. With a focus on energy efficiency, these solutions contribute to creating sustainable and comfortable living spaces.
                    </div>
                </div>
            </div>
            <img src="../../assets/IoT_img_3.webp" class="common-block-img">
        </div>
        <div class="existing-margin-bottom-200"></div>

        <div class="common-block-image iot-img-bg-5 backImgStyle">
            <div class="common-block-image-text">
                Occupancy & People Counting Series
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                100% Anonymous Monitoring
            </div>
            <div class="common-part-content">
                Step into the future of occupancy monitoring with our advanced People Counting Series, ensuring accuracy, compliance, and anonymity. From high-ceiling workplace sensors to desk and seat occupancy solutions, our range combines innovative technologies to provide unparalleled insights while prioritizing user privacy.
                <br/><br/>
                People Counting is a revolutionary technology applied in workplaces for efficient people flow analytics, optimizing customer flow in businesses like retail stores and supermarkets, and contributing to smart building management. This innovative solution extends its impact to transportation systems, providing valuable insights into passenger flow analytics, and even optimizing smart restroom usage frequency.
            </div>
        </div>
        <div class="common-block-left-right existing-part-padding-160">
            <img src="../../assets/IoT_img_4.webp" class="common-block-img">
            <div class="common-block-left-right-info">
                <div class="common-block-right-info-container">
                    <div class="common-block-left-right-title">
                        Tailored Sensor Solutions
                    </div>
                    <div class="common-block-left-right-text">
                        Explore a diverse range of sensors designed to cater to your specific needs. From the advanced AI Workplace Occupancy Sensor and AI ToF People Counting Sensor to the versatile Cubicle Occupancy Sensor, Desk & Seat Occupancy Sensor, Passage People Counter, and more.
                    </div>
                </div>
            </div>
        </div>
        <div class="common-block-left-right common-flex-reverse existing-part-padding-160">
            <div class="common-block-left-right-info">
                <div class="common-block-left-info-container">
                    <div class="common-block-left-right-title">
                        Precision, Efficiency, and Anonymity
                    </div>
                    <div class="common-block-left-right-text">
                        <ul>
                            <li>On-board AI algorithm with 2nd generation ToF technology.</li>
                            <li>Up to 99.8% accuracy, staff detection, and adults/children differentiation.</li>
                            <li>Bi-directional people counting with smart U-turn detection.</li>
                            <li>Compliant with GDPR, ensuring 100% anonymous detection.</li>
                            <li>Real-time data collection, support local storage of 1,000 historical records.</li>
                            <li>Low power consumption, battery life up to 4 years.</li>
                            <li>LoRaWAN° wireless deployment, support multi-device stitching.</li>
                            <li>Combine highly sensitive PIR & Thermopile, adjustable detection angle.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <img src="../../assets/IoT_img_5.webp" class="common-block-img">
        </div>
        <div class="existing-margin-bottom-200"></div>

        <div class="common-block-image iot-img-bg-6 backImgStyle">
            <div class="common-block-image-text">
                Environment Monitoring Sensor
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Monitor Conditions and Optimize Controls
            </div>
            <div class="common-part-content">
                In today's fast-paced world, keeping tabs on environmental conditions is crucial for various sectors. Introducing our industrial-grade environment monitoring sensor designed to optimize controls and enhance overall efficiency.
            </div>
        </div>
        <div class="common-block-left-right existing-part-padding-160">
            <img src="../../assets/IoT_img_6.webp" class="common-block-img">
            <div class="common-block-left-right-info">
                <div class="common-block-right-info-container">
                    <div class="common-block-left-right-title">
                        Slimmer, Superior and Safer
                    </div>
                    <div class="common-block-left-right-text">
                        <ul>
                            <li>Industrial-grade Design</li>
                            <li>Support Extra Temperature and Humidity</li>
                            <li>Detection (Except UDL&TILT&MLD)</li>
                            <li>Rated at IP67, robust and waterproof, suitable for harsh conditions.</li>
                            <li>Battery Life up to 5 Years, the sensors provide long-term, hassle-free monitoring.</li>
                            <li>Easy Configuration via NFC</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-part existing-part-padding-160 existing-margin-bottom-200">
            <div class="common-part-title">
                Creating Smarter Spaces
            </div>
            <div class="common-part-content">
                Environment Monitoring Sensors are versatile solutions utilized across various industries to maintain optimal conditions and efficiency. In smart offices, they ensure a comfortable work environment by monitoring air quality, temperature, and humidity. These sensors contribute to a pleasant and healthy atmosphere in smart hotels. Industries, including manufacturing, benefit from detecting and responding to environmental changes. For water management, the sensors detect leaks and monitor water levels. They play a crucial role in security by acting as magnetic contact switch sensors for doors and windows. In healthcare, the food industry, and facility management, they ensure sterile conditions, proper food storage, and overall facility efficiency. With IoT compatibility, these sensors enable data-driven decision-making for enhanced operational efficiency.
            </div>
        </div>

        <div class="common-block-image iot-img-bg-7 backImgStyle">
            <div class="common-block-image-text">
                Distance / Level Measurement Sensor
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Revolutionizing Measurement and Monitoring
            </div>
            <div class="common-part-content">
                Experience the future of waste management and parking optimization with Wove's Smart Bin Solution. This innovative solution provides real-time indicators of garbage levels in waste bins, allowing for efficient waste collection route optimization and a significant reduction in fuel consumption.
            </div>
        </div>
        <div class="common-block-left-right existing-part-padding-160">
            <img src="../../assets/IoT_img_7.webp" class="common-block-img">
            <div class="common-block-left-right-info">
                <div class="common-block-right-info-container">
                    <div class="common-block-left-right-title">
                        Highly Specialized, Reliable and Durable
                    </div>
                    <div class="common-block-left-right-text">
                        <ul>
                            <li>IP67-rated for durability in diverse conditions</li>
                            <li>LoRaWAN® & NB IoT versions for seamless connectivity</li>
                            <li>Embedded 3-Axis Accelerometer & NTC Thermistor for enhanced capabilities</li>
                            <li>Replaceable Battery with a lifespan of up to 10 years</li>
                            <li>Easy Configuration via NFC for user-friendly setup</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-part existing-part-padding-160 existing-margin-bottom-200">
            <div class="common-part-title">
                Smart Bin Solution for a More Intelligent Urban Environment
            </div>
            <div class="common-part-content">
                Smart Bin Solution is designed for remote and precise monitoring of waste fill levels, triggering alarms when bins are nearly full. This proactive approach not only reduces costs but also minimizes energy consumption and carbon emissions.
                <br/><br/>
                In the realm of parking management, this solution plays a pivotal role by capturing real-time parking occupancy information. It ensures a streamlined driving experience, guiding drivers to the best available parking spaces and saving time, energy, and money.
                <br/><br/>
                With advanced features like device motion detection, high accuracy through ToF, and user-friendly pre-set modes, this solution is at the forefront of innovation in environmental monitoring.
            </div>
        </div>

        <div class="common-bottom-contact-container iot-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br/>
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactUsButton from '@/components/contactUsButton.vue'
export default defineComponent({
    name: "IoT",
    components: {
        ContactUsButton,
    },
    data() {
        return {
            IOTActiveName: 'Edge Computing Gateway'
        }
    },
    created() {},
    mounted() {
    },
    methods: {
        handleTabClick(tab, e) {
            // console.log('handleTabClick=====', tab, e, this.IOTActiveName);
        },
        toContactView() {
            this.$router.push({path: '/contactUs'});
        },
    }
})
</script>

<style lang="scss" src="../style/iot.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
<template>
    <div class="app-footer">
        <!--Desktop-start==============================================================-->
        <div class="footer-box-Desktop footer-box" style="width: 230px">
            <div><img src="../../assets/header_logo.svg" class="footer-logo"></div>
            <div style="margin-top: 40px">
                Wove Technologies strives<br/>
                to empower businesses and<br/>
                communities through<br/>
                cutting-edge hardware and<br/>
                software solutions, fostering<br/>
                a smarter, more<br/>
                interconnected world.</div>
        </div>

        <div class="footer-box-Desktop footer-box" style="width: 230px">
            <div class="footer-title">Site Info</div>
            <div style="margin-top: 50px">
                <a class="footer-text footer-link pointer" href="/">Home</a>
                <a class="footer-text footer-link pointer tMgt8" href="/contactUs">Contact Us</a>
                <a class="footer-text footer-link pointer tMgt8" href="/about">About Us</a>
            </div>
        </div>

        <div class="footer-box-Desktop footer-box" style="width: 230px">
            <div class="footer-title">Services</div>
            <div style="margin-top: 50px">
                <a class="footer-text pointer footer-link" href="/infrastructure" @click="linkTo('/infrastructure', {})">Infrastructure</a>
                <a class="footer-text footer-link pointer tMgt8" href="/buildingDevelopment" @click="linkTo('/governmentAndEnterpriseOffice', {})">New Building</a>
                <a class="footer-text footer-link pointer tMgt8" href="/existingBuilding" @click="linkTo('/existingBuilding', {})">Existing Building</a>
                <a class="footer-text footer-link pointer tMgt8" href="/iot" @click="linkTo('/iot', {})">IOT</a>
                <a class="footer-text footer-link pointer tMgt8" href="/software" @click="linkTo('/software', {})">Software</a>
                <a class="footer-text footer-link pointer tMgt8" href="/hardware" @click="linkTo('/hardware', {})">Hardware</a>
                <a class="footer-text footer-link pointer tMgt8" href="/meter" @click="linkTo('/meter', {})">Intelligent Watt-Hour<br/>Meter</a>
            </div>
        </div>

        <div class="footer-box-Desktop footer-box" style="width: 230px">
            <div class="footer-title">Contact Us</div>
            <div style="margin-top: 30px">
<!--                <div class="footer-contact-item">-->
<!--                    <div class="footer-contact-title">Phone:</div>-->
<!--                    <div class="footer-text">xxx</div>-->
<!--                </div>-->
                <div class="footer-contact-item">
                    <div class="footer-contact-title">Email:</div>
                  <a target="_blank" href="mailto:contact@wovetech.co">
                    <div class="footer-text">contact@wovetech.co</div>
                  </a>
                </div>
                <div class="footer-contact-item">
                    <div class="footer-contact-title">Address:</div>
                  <a target="_blank" href="https://maps.app.goo.gl/2RCbCcCNEGcWJeTAA">
                    <div class="footer-text">151 Yonge st, suite 1137<br/>
                      Toronto, ON, M5C 2W7. Canada</div>
                  </a>
                </div>
                <div class="footer-contact-item" style="margin-top: 40px">
                    <a target="_blank" href="https://www.instagram.com/wovetech/">
                        <img src="../../assets/instagram-white-icon.svg" class="footer-icon">
                    </a>
                    <a target="_blank"  href="https://twitter.com/WoveTech">
                        <img src="../../assets/Twitter_X.webp" class="footer-icon" style="margin-left: 37px">
                    </a>
<!--                    <img src="../../assets/linkedin-svgrepo-com%201.webp" class="footer-icon" style="margin-left: 37px">-->
                </div>
            </div>
        </div>
        <!--Desktop-end==============================================================-->

        <!--Tablet-start==============================================================-->
        <div class="footer-line-box-Tablet">
            <div class="footer-box-Tablet footer-box">
                <div><img src="../../assets/header_logo.svg" class="footer-logo-Tablet"></div>
                <div style="margin-top: 16px; opacity: 0.8">
                    Wove Technologies strives<br/>
                    to empower businesses<br/>
                    and communities through<br/>
                    cutting-edge hardware<br/>
                    and software solutions,<br/>
                    fostering a smarter, more<br/>
                    interconnected world.</div>
            </div>
            <div class="footer-box-Tablet footer-box" style="margin-left: 73px">
                <div class="footer-title">Site Info</div>
                <div style="margin-top: 24px; opacity: 0.8">
                    <a class="footer-text footer-link pointer" href="/" @click="linkTo('/', {})">Home</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/contactUs">Contact Us</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/about">About Us</a>
                </div>
            </div>
        </div>
        <div class="footer-line-box-Tablet footer-box">
            <div class="footer-box-Tablet footer-box">
                <div class="footer-title">Services</div>
                <div style="margin-top: 24px; opacity: 0.8">
                    <a class="footer-text pointer footer-link" href="/infrastructure" @click="linkTo('/infrastructure', {})">Infrastructure</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/buildingDevelopment" @click="linkTo('/governmentAndEnterpriseOffice', {})">New Building</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/existingBuilding" @click="linkTo('/existingBuilding', {})">Existing Building</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/iot" @click="linkTo('/iot', {})">IOT</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/software" @click="linkTo('/software', {})">Software</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/hardware" @click="linkTo('/hardware', {})">Hardware</a>
                    <a class="footer-text footer-link pointer tMgt8" href="/meter" @click="linkTo('/meter', {})">Intelligent Watt-Hour<br/>Meter</a>
                </div>
            </div>
            <div class="footer-box-Tablet footer-box" style="margin-left: 73px">
                <div class="footer-title">Contact Us</div>
                <div style="margin-top: 24px; opacity: 0.8">
<!--                    <div class="footer-contact-item">-->
<!--                        <div class="footer-contact-title">Phone:</div>-->
<!--                        <div class="footer-text">xxx</div>-->
<!--                    </div>-->
                    <div class="footer-contact-item">
                        <div class="footer-contact-title">Email:</div>
                      <a target="_blank" href="mailto:contact@wovetech.co">
                        <div class="footer-text">contact@wovetech.co</div>
                      </a>
                    </div>
                    <div class="footer-contact-item">
                        <div class="footer-contact-title">Address:</div>
                      <a target="_blank" href="https://maps.app.goo.gl/2RCbCcCNEGcWJeTAA">
                        <div class="footer-text">151 Yonge st, suite 1137<br/>
                          Toronto, ON, M5C 2W7. Canada</div>
                      </a>
                    </div>
                </div>
                <div class="footer-contact-item" style="margin-top: 40px">
                    <a target="_blank"  href="https://www.instagram.com/wovetech/">
                        <img src="../../assets/instagram-white-icon.svg" class="footer-icon">
                    </a>
                    <a target="_blank"  href="https://twitter.com/WoveTech">
                        <img src="../../assets/Twitter_X.webp" class="footer-icon" style="margin-left: 37px">
                    </a>
<!--                    <img src="../../assets/linkedin-svgrepo-com%201.webp" class="footer-icon" style="margin-left: 37px">-->
                </div>
            </div>
        </div>
        <!--Tablet-end==============================================================-->

        <!--Mobile-start==============================================================-->
        <div class="footer-box-Mobile footer-box">
            <div><img src="../../assets/footer_logo_mobile.svg" class="footer-logo-Mobile"></div>
            <div style="margin-top: 16px; opacity: 0.8; font-size: 16px; letter-spacing: 2px;">
                Wove Technologies strives<br/>
                to empower businesses<br/>
                and communities through<br/>
                cutting-edge hardware<br/>
                and software solutions,<br/>
                fostering a smarter, more<br/>
                interconnected world.</div>
        </div>

        <div class="footer-box-Mobile footer-box tMgt40">
            <div class="footer-title">Services</div>
            <div style="margin-top: 24px; opacity: 0.8; font-size: 16px; letter-spacing: 2px;">
                <a class="footer-text pointer footer-link" href="/infrastructure" @click="linkTo('/infrastructure', {})">Infrastructure</a>
                <a class="footer-text footer-link pointer tMgt8" href="/buildingDevelopment" @click="linkTo('/governmentAndEnterpriseOffice', {})">New Building</a>
                <a class="footer-text footer-link pointer tMgt8" href="/existingBuilding" @click="linkTo('/existingBuilding', {})">Existing Building</a>
                <a class="footer-text footer-link pointer tMgt8" href="/iot" @click="linkTo('/iot', {})">IOT</a>
                <a class="footer-text footer-link pointer tMgt8" href="/software" @click="linkTo('/software', {})">Software</a>
                <a class="footer-text footer-link pointer tMgt8" href="/hardware" @click="linkTo('/hardware', {})">Hardware</a>
                <a class="footer-text footer-link pointer tMgt8" href="/meter" @click="linkTo('/meter', {})">Intelligent Watt-Hour<br/>Meter</a>
            </div>
        </div>

        <div class="footer-box-Mobile footer-box tMgt65">
            <div class="footer-title">Site Info</div>
            <div style="margin-top: 24px; opacity: 0.8; font-size: 16px; letter-spacing: 2px;">
                <a class="footer-text footer-link pointer" href="/">Home</a>
                <a class="footer-text footer-link pointer tMgt8" href="/contactUs">Contact Us</a>
                <a class="footer-text footer-link pointer tMgt8" href="/about">About Us</a>
            </div>
        </div>

        <div class="footer-box-Mobile footer-box tMgt65">
            <div class="footer-title">Contact Us</div>
            <div style="margin-top: 24px; opacity: 0.8; font-size: 16px; letter-spacing: 2px;">
<!--                <div class="footer-contact-item">-->
<!--                    <div class="footer-contact-title">Phone:</div>-->
<!--                    <div class="footer-text">xxx</div>-->
<!--                </div>-->
                <div class="footer-contact-item">
                    <div class="footer-contact-title">Email:</div>
                  <a target="_blank" href="mailto:contact@wovetech.co">
                    <div class="footer-text">contact@wovetech.co</div>
                  </a>
                </div>
                <div class="footer-contact-item">
                    <div class="footer-contact-title">Address:</div>
                  <a target="_blank" href="https://maps.app.goo.gl/2RCbCcCNEGcWJeTAA">
                    <div class="footer-text">151 Yonge st, suite 1137<br/>
                      Toronto, ON, M5C 2W7. Canada</div>
                  </a>
                </div>
            </div>
            <div class="footer-contact-item" style="margin-top: 40px">
                <a target="_blank" href="https://www.instagram.com/wovetech/">
                    <img src="../../assets/instagram-white-icon.svg" class="footer-icon">
                </a>
                <a target="_blank" href="https://twitter.com/WoveTech">
                    <img src="../../assets/Twitter_X.webp" class="footer-icon" style="margin-left: 37px">
                </a>
<!--                <img src="../../assets/linkedin-svgrepo-com%201.webp" class="footer-icon" style="margin-left: 37px">-->
            </div>
        </div>
        <!--Mobile-end==============================================================-->
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: "Footer",
    methods: {
        linkTo(url: any, query: any = {}) {
            let toUrl = typeof url === 'string' ? url : '/';
            let curPath = this.$route.path;
            if (curPath === url) {
                this.$router.push({ path: toUrl, query: query });
                setTimeout(() => {
                    window.location.reload();
                });
            } else {
                // this.$router.push({ path: toUrl, query: query });
            }
        },
    }
})
</script>

<style lang="scss" src="./index.scss" scoped />

<template>
    <div class="hardware-scroll-block">
        <div v-for="(info, index) of infoList" class="hardware-scroll-item">
            <img :src="require(`../../assets/Hardware_Professional_img_${index + 1}.webp`)" class="hardware-scroll-block-img">
            <div class="hardware-scroll-block-name">{{ info.name }}wq</div>
            <ul>
                <li v-for="text of info.infos" class="hardware-scroll-block-info">
                    {{ text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name: "ProfessionalList",
    data() {
        return {
            infoList: [
                {
                    index: 1,
                    name: 'Philips T-Line 24BDL4151T',
                    infos: [
                        'Plug-and-play 10-point Multi-Touch display',
                        'Integrated mPCIe slot for optional 4G/LTE'
                    ]
                },
                {
                    index: 2,
                    name: 'Philips D-Line 75BDL4150D',
                    infos: [
                        '4K UHD',
                        'IPS panel'
                    ]
                },
                {
                    index: 3,
                    name: 'Philips B-Line 65BFL2114',
                    infos: [
                        'Professional TV powered by Android™',
                        'Chromecast built-in'
                    ]
                },
                {
                    index: 4,
                    name: 'Philips P-Line Display 43BDL3017P',
                    infos: [
                        'UHD picture quality',
                        'D-image:clinically accurate imaging'
                    ]
                },
                {
                    index: 5,
                    name: 'Philips L-Line 6000 Series',
                    infos: [
                        'Bezel-free LED display wall',
                        'Building block of 4-in-1 mini LED to achieve'
                    ]
                },
                {
                    index: 6,
                    name: 'Philips Tableaux 13BDL4150IW/00',
                    infos: [
                        'Full-colour E-paper Signage',
                        'Show content 24/7 with zero power'
                    ]
                },
                {
                    index: 7,
                    name: 'Philips X-Line 55BDL8007X/00',
                    infos: [
                        'Full HD Videowall display',
                        'Energy-saving automatic backlight control'
                    ]
                },
                {
                    index: 8,
                    name: 'Philips Tableaux 25BDL4150I/00',
                    infos: [
                        'Full colour E-paper signage solution',
                        'Low to no power consumption'
                    ]
                },
                {
                    index: 9,
                    name: 'Philips Q-Line 50BDL3650Q/00',
                    infos: [
                        '4K Ultra HD Direct LED Backlight signage solution',
                        'Wave-ready for remote management'
                    ]
                },
                {
                    index: 10,
                    name: 'Philips H-Line 75BDL4003H/00',
                    infos: [
                        'Ultra-HD High bright 24/7 display',
                        'CMND & Create. Develop and launch your own content'
                    ]
                },
                {
                    index: 11,
                    name: 'Philips MediaSuite 50HFL6214U/12',
                    infos: [
                        'Professional TV with smarter connectivity',
                        'Chromecast built-in and Netflix'
                    ]
                },
                {
                    index: 12,
                    name: 'Philips L-Line 7000 Series',
                    infos: [
                        'Direct View LED signage solution',
                        'Seamless linking and multiple size options'
                    ]
                },
                {
                    index: 13,
                    name: 'Philips E-Line 86BDL4152E/00',
                    infos: [
                        'Powered by Android 13',
                        'Multi-touch (up to 40 touchpoints)'
                    ]
                },
            ],
        }
    },
})
</script>

<style lang="scss" scoped></style>
<template>
    <div class="common-main hardware-main">
        <div class="common-background-top transportation-background-top backImgStyle">
            <div class="common-title">
                Hardware
            </div>
        </div>

        <div class="common-block-image hardware-img-bg-1 backImgStyle">
            <div class="common-block-image-text">
                Energy Management System Architecture
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Energizing Business Efficiency
            </div>
            <div class="common-part-content">
                Our Energy Management System Architecture is your key to optimizing energy usage, cutting costs, and embracing sustainability. Transform the way your business operates and step into a future of smarter, greener, and more cost-effective practices.
            </div>
        </div>
<!--        <div class="common-block-container existing-part-padding-100"></div>-->
        <div class="hardware-img-container">
            <viewer
                :images="[require('../../assets/Hardware_img.webp')]"
                :options="{ title: false, rotatable: false, scalable: false, navbar: false, toolbar: false }"
            >
                <img src="../../assets/Hardware_img.webp" class="hardware-img">
            </viewer>
<!--            <img src="../../assets/Hardware_img.webp" class="hardware-img" @click="showViewer">-->

<!--            <el-image-->
<!--                class="hardware-img mobile-img-preview"-->
<!--                :src="require('../../assets/Hardware_img.webp')"-->
<!--                :zoom-rate="1.2"-->
<!--                :max-scale="7"-->
<!--                :min-scale="0.2"-->
<!--                :preview-src-list="[require('../../assets/Hardware_img.webp')]"-->
<!--                :initial-index="4"-->
<!--                fit="cover"-->
<!--            />-->
        </div>

        <div class="common-block-image hardware-img-bg-2 backImgStyle">
            <div class="common-block-image-text">
                The Core of Control - Industrial Grade IoT Edge Computer
            </div>
        </div>
        <div class="common-block-container existing-part-padding-220">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        High specification industrial performance
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Flexible and easy network integration
                        </li>
                        <li>
                            Dual-CPU redundancy
                        </li>
                        <li>
                            Hot-swappable with power on
                        </li>
                        <li>
                            Power redundancy
                        </li>
                        <li>
                            Communication redundancy
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        High-precision intelligent analog module
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            More than 10 network architecture options
                        </li>
                        <li>
                            All Ethernet main controllers make IoT integration easier.
                        </li>
                        <li>
                            Third-party communication integration on the main controller side
                        </li>
                        <li>
                            Achieves true offline operation
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Open Architecture
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Truly open architecture, can be freely combined with third-party configuration software, controllers, and expansion IO modules.
                        </li>
                        <li>
                            Supports (built-in) open communication protocols: Modbus TCP, Modbus RTU, LonWorks, BACNet, and other communication protocols.
                        </li>
                        <li>
                            Programming complies with IEC61131-3 standards, supports 6 programming languages.
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="common-block-image hardware-img-bg-3 backImgStyle">
            <div class="common-block-image-text">
                Professional Display
            </div>
        </div>
        <div class="common-part existing-part-padding-160">
            <div class="common-part-title">
                Futuristic Displays, Seamless Solutions
            </div>
            <div class="common-part-content">
                Step into the future of display technology with our cutting-edge solutions. From state-of-the-art CMS Information Publishing Systems to interactive displays, videowalls, and outdoor LED displays, we redefine the way you engage visually. With features like remote scheduling and 24/7 content precision, effortlessly elevate how you convey information.
            </div>
        </div>
<!--        <div class="common-part existing-part-padding-160">-->
<!--            <div class="common-part-title">-->
<!--                Our Brands-->
<!--            </div>-->
<!--            <div class="hardware-icon-list">-->
<!--                <img src="../../assets/icon_GreatWall.svg" style="filter: brightness(0)">-->
<!--                <img src="../../assets/icon_AGON.svg" style="filter: brightness(0)">-->
<!--                <img src="../../assets/icon_ENVISION.svg" style="filter: grayscale(1)">-->
<!--                <img src="../../assets/icon_PHILIPS.svg" style="filter: brightness(0)">-->
<!--                <img src="../../assets/icon_AOC.svg" style="filter: brightness(0)">-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="common-part existing-part-padding-160">-->
<!--            <div class="common-part-title">-->
<!--                Monitor-->
<!--            </div>-->
<!--            <ScrollBlock scrollId="scroll1" :blockWidth="blockWidth" :itemNum="24">-->
<!--                <template v-slot:content>-->
<!--                    <MonitorList />-->
<!--                </template>-->
<!--            </ScrollBlock>-->
<!--        </div>-->

<!--        <div class="common-part existing-part-padding-160">-->
<!--            <div class="common-part-title">-->
<!--                TV-->
<!--            </div>-->
<!--            <ScrollBlock scrollId="scroll2" :blockWidth="blockWidth" :itemNum="11">-->
<!--                <template v-slot:content>-->
<!--                    <TVList />-->
<!--                </template>-->
<!--            </ScrollBlock>-->
<!--        </div>-->

<!--        <div class="common-part existing-part-padding-160">-->
<!--            <div class="common-part-title">-->
<!--                Professional Display-->
<!--            </div>-->
<!--            <ScrollBlock scrollId="scroll3" :blockWidth="blockWidth" :itemNum="13">-->
<!--                <template v-slot:content>-->
<!--                    <ProfessionalList />-->
<!--                </template>-->
<!--            </ScrollBlock>-->
<!--        </div>-->
        <div class="existing-margin-bottom-80"></div>

        <div class="common-bottom-contact-container hardware-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized Hardware Offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we are committed to providing tailored solutions to meet your specific requirements.
                Contact us for more information on how we can create bespoke solutions to enhance your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactUsButton from '@/components/contactUsButton.vue';
import ScrollBlock from '@/components/scrollBlock.vue';
import MonitorList from "@/views/Hardware/MonitorList";
import TVList from "@/views/Hardware/TVList";
import ProfessionalList from "@/views/Hardware/ProfessionalList";
import { api as viewerApi } from 'v-viewer';
export default defineComponent({
    name: "Hardware",
    components: {
        ContactUsButton,
        ScrollBlock,
        MonitorList,
        TVList,
        ProfessionalList,
    },
    data() {
        return {
            IOTActiveName: 'Edge Computing Gateway',
            blockWidth: 379,
        }
    },
    created() {},
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        showViewer() {
            // console.log('showViewer')
            let options = { title: false, rotatable: false, scalable: false, navbar: false };
            let urls = [require('../../assets/Hardware_img.webp')];
            const $viewer = viewerApi({
                options: options,
                images: urls
            })
            // setTimeout(() => {
            //     $viewer.zoomTo(1 )
            // }, 700)
        },
        handleResize() {
            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // console.log('width', windowWidth);
            // desktop,tablet : 379    mobile: 290
            if (windowWidth > 767) {
                this.blockWidth = 379;
            } else {
                this.blockWidth = 290;
            }
        },
        toContactView() {
            this.$router.push({path: '/contactUs'});
        },
    }
})
</script>

<style lang="scss" src="../style/hardware.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
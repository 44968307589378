<template>
  <div>

  </div>
    <div :class="{
        'home-carousel': true,
        'event-none': loading,
    }"
         id="home-carousel"
         @touchstart="handleTouchStart"
         @touchend="handleTouchEnd"
    >
<!--        <div class="home-carousel-background-img-container">-->
<!--            <div-->
<!--                :class="{-->
<!--                'home-carousel-item home-carousel-background-img img-NewBuildingSolutions backImgStyle': true,-->
<!--                'home-carousel-background-img-show': backImgIndex === 0-->
<!--                }"></div>-->
<!--            <div-->
<!--                :class="{-->
<!--                'home-carousel-item home-carousel-background-img img-ExistingBuildingUpgrades backImgStyle': true,-->
<!--                'home-carousel-background-img-show': backImgIndex === 1-->
<!--                }"></div>-->
<!--            <div-->
<!--                :class="{-->
<!--                'home-carousel-item home-carousel-background-img img-Infrastructure backImgStyle': true,-->
<!--                'home-carousel-background-img-show': backImgIndex === 2-->
<!--                }"></div>-->
<!--        </div>-->

        <div id="home-carousel-container" style="height: 900px">
            <div
                id="home-carousel-background-img-container"
                class="home-carousel-background-img-container" style="left: 0">
                <div class="home-carousel-item-container">
                    <div class="home-carousel-item home-carousel-updown-left">
                        <div class="home-carousel-item-title">New Building <br class="home-carousel-item-title-br"/>Solutions</div>
                        <div class="home-carousel-item-text">
                            Infuse intelligence from the ground up with our smart technologies.
                            We specialize in crafting <br class="home-carousel-item-text-br" />tech-savvy spaces,
                            guaranteeing your new building boasts the latest in software,
                            hardware, and infrastructure for a modern and efficient environment.
                        </div>
                        <ContactUsButton text="Learn More" color="blue" class="home-carousel-item-contact" :routeInfo="{ path:'/buildingDevelopment' }" />
                    </div>
                    <div class="home-carousel-updown-top img-NewBuildingSolutions backImgStyle"></div>
                </div>
                <div class="home-carousel-item-container">
                    <div class="home-carousel-item home-carousel-updown-left">
                        <div class="home-carousel-item-title">Existing Building <br class="home-carousel-item-title-br"/>Upgrades</div>
                        <div class="home-carousel-item-text">
                            Our solutions seamlessly integrate with your current setup,
                            providing a cost-effective way to enhance efficiency, security, and connectivity.
                            <br/>
                            <br/>
                            Transform your existing building into a smart,
                            responsive environment that meets the demands of contemporary living and working.
                        </div>
                        <ContactUsButton text="Learn More" color="blue" class="home-carousel-item-contact" :routeInfo="{ path:'/existingBuilding' }" />
                    </div>
                    <div class="home-carousel-updown-top img-ExistingBuildingUpgrades backImgStyle"></div>
                </div>
                <div class="home-carousel-item-container">
                    <div class="home-carousel-item home-carousel-updown-left">
                        <div class="home-carousel-item-title">Infrastructure</div>
                        <div class="home-carousel-item-text">
                            We specialize in creating infrastructures that
                            seamlessly integrate with your existing systems,
                            maximizing efficiency while minimizing disruptions.
                            <br/>
                            <br/>
                            Whether upgrading or integrating new technologies,
                            our solutions ensure a connected and agile environment.
                        </div>
                        <ContactUsButton text="Learn More" color="blue" class="home-carousel-item-contact" :routeInfo="{ path:'/infrastructure' }" />
                    </div>
                    <div class="home-carousel-updown-top img-Infrastructure backImgStyle"></div>
                </div>
            </div>
        </div>



        <div class="home-carousel-fill"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactUsButton from "@/components/contactUsButton.vue";
export default defineComponent ({
    name: "HomeCarousel",
    components: {
        ContactUsButton
    },
    data() {
        return {
            scrollTop: 0,
            loading: false,
            windowWidth: 0,
            handleTouch: false,
            backImgIndex: 0,
            carouselEl: null as any,
            carouselElContainer: null as any,
            carouselElImgContainer: null as any,
            lastCarouselClientTop: 0,
            inScroll: false,
            showMode: 'Desktop',
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleBodyScroll);
        this.windowWidth = window.innerWidth;
        this.carouselEl = document.getElementById("home-carousel");
        this.carouselElContainer = document.getElementById("home-carousel-container");
        this.carouselElImgContainer = document.getElementById("home-carousel-background-img-container");

        if (this.windowWidth > 1024) {
            this.showMode = 'Desktop';
        } else if (this.windowWidth >= 768 && this.windowWidth <= 1024) {
            this.showMode = 'Tablet';
        } else {
            this.showMode = 'Mobile';
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleBodyScroll);
    },
    methods: {
        handleTouchStart() {
            // this.handleTouch = true;
        },
        handleTouchEnd() {
            // this.handleTouch = false;
            // this.scrollAction();
            // console.log(this.scrollTop)
        },
        handleBodyScroll() {
            // console.log('handleBodyScroll', this.carouselEl.getBoundingClientRect());
            let carouselClient = this.carouselEl.getBoundingClientRect();
            if (this.showMode === 'Desktop' && !this.inScroll && carouselClient.top < 360 && carouselClient.top > 160 && this.lastCarouselClientTop > carouselClient.top) {
                this.inScroll = true;
                this.windowScrollTo(this.carouselEl.offsetTop);
            }

            // ======================= Desktop--start =======================
            // carousel height 900
            let heightValue = (window.innerHeight - 80) / 3;    // 居中高度调整量
            if (this.showMode === 'Desktop' && carouselClient.top <= 80 && carouselClient.top >= -(1700 - heightValue)) {
                // this.carouselElContainer.style.position = 'fixed';
                // this.carouselElContainer.style.top = '80px';
                // this.carouselElContainer.style.height = 'calc(100% - 80px)';
                // this.carouselElContainer.style.alignItems = 'center';

                if (carouselClient.top >= -500) {
                    this.carouselElImgContainer.style.left = '0'
                } else if (carouselClient.top < -500 && carouselClient.top >= -1180) {
                    this.carouselElImgContainer.style.left = '-100%'
                } else if (carouselClient.top < -1180) {
                    this.carouselElImgContainer.style.left = '-200%'
                }
            } else if(this.showMode === 'Desktop' && carouselClient.top < -(1700 - heightValue)) {
                // this.carouselElContainer.style.position = 'absolute';
                // this.carouselElContainer.style.top = `${1800 - heightValue}px`;
                // this.carouselElContainer.style.height = '900px';
                // this.carouselElContainer.style.alignItems = 'initial';

                // this.carouselElContainer.style.position = 'fixed';
                // this.carouselElContainer.style.top = `${(window.innerHeight -80 ) / 2 - 370}px`;
                // this.carouselElContainer.style.height = '900px';
                // this.carouselElContainer.style.alignItems = 'center';
            } else if (this.showMode === 'Desktop' && carouselClient.top >= 0) {
                this.carouselElContainer.style.position = 'absolute';
                this.carouselElContainer.style.top = '0';
                this.carouselElContainer.style.height = '900px';
                this.carouselElContainer.style.alignItems = 'center';
            }
            if (this.showMode === 'Desktop' && this.carouselElContainer.getBoundingClientRect().top <= ((window.innerHeight -80 ) / 2 - 370)) {
                this.carouselElContainer.style.position = 'fixed';
                this.carouselElContainer.style.top = `${(window.innerHeight -80 ) / 2 - 370}px`;
            }
            // ======================= Desktop--end =======================

            // ======================= Tablet--start =======================
            // carousel height 560
            if (this.showMode === 'Tablet' && carouselClient.top <= 80 && carouselClient.top >= -(940 - heightValue)) {
                // this.carouselElContainer.style.position = 'fixed';
                // this.carouselElContainer.style.top = '80px';
                if (carouselClient.top >= -60) {
                    this.carouselElImgContainer.style.left = '0'
                } else if (carouselClient.top < -60 && carouselClient.top >= -520) {
                    this.carouselElImgContainer.style.left = '-100%'
                } else if (carouselClient.top < -520) {
                    this.carouselElImgContainer.style.left = '-200%'
                }
            } else if(this.showMode === 'Tablet'&& carouselClient.top <= -(940 - heightValue)) {
                // this.carouselElContainer.style.position = 'absolute';
                // this.carouselElContainer.style.top = `${1020 - heightValue}px`;
            } else if (this.showMode === 'Tablet'&& carouselClient.top >= 0) {
                this.carouselElContainer.style.position = 'absolute';
                this.carouselElContainer.style.top = '0';
            }
            if (this.showMode === 'Tablet' && this.carouselElContainer.getBoundingClientRect().top <= ((window.innerHeight -80 ) / 2 - 200)) {
                this.carouselElContainer.style.position = 'fixed';
                this.carouselElContainer.style.top = `${(window.innerHeight -80 ) / 2 - 200}px`;
            }
            // ======================= Tablet--end =======================

            // ======================= Mobile--start =======================
            // carousel height 720
            if (this.showMode === 'Mobile' && carouselClient.top <= 80 && carouselClient.top >= -(1400 - heightValue)) {
                this.carouselElContainer.style.position = 'fixed';
                this.carouselElContainer.style.top = '80px';
                if (carouselClient.top >= -300) {
                    this.carouselElImgContainer.style.left = '0'
                } else if (carouselClient.top < -300 && carouselClient.top >= -800) {
                    this.carouselElImgContainer.style.left = '-100%'
                } else if (carouselClient.top < -800) {
                    this.carouselElImgContainer.style.left = '-200%'
                }
            } else if(this.showMode === 'Mobile'&& carouselClient.top < -(1340 - heightValue)) {
                this.carouselElContainer.style.position = 'absolute';
                this.carouselElContainer.style.top = `${1490 - heightValue}px`;
            } else if (this.showMode === 'Mobile'&& carouselClient.top >= 0) {
                this.carouselElContainer.style.position = 'absolute';
                this.carouselElContainer.style.top = '0';
            }
            if (this.showMode === 'Mobile' && this.carouselElContainer.getBoundingClientRect().top <= 80) {
                this.carouselElContainer.style.position = 'fixed';
                this.carouselElContainer.style.top = `80px`;
            }
            // ======================= Mobile--end =======================

            this.lastCarouselClientTop = carouselClient.top;
        },
        windowScrollTo(top: number) {
            if(window.scrollY === top) return;
            let originalDifference = window.scrollY - top;
            let scrollInterval = setInterval(() => {
                // console.log('Interval')
                let curDifference = window.scrollY - top;
                if(window.scrollY === top || (originalDifference > 0 && curDifference < 0) || (originalDifference < 0 && curDifference > 0)) {
                    clearInterval(scrollInterval)
                    this.inScroll = false;
                    // console.log('clearInterval')
                    return;
                }
                window.scrollTo({
                    top: window.scrollY > top ? window.scrollY - 2 : window.scrollY + 2
                })
            }, 1);
        },
    },
})
</script>

<style lang="scss" src="./style/homeCarousel.scss" scoped />
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ContactView from "@/views/ContactView.vue";
import AboutView from "@/views/AboutView.vue";
import TransportationView from "@/views/Infrastructure/TransportationView.vue";
import BuildingDevelopmentView from "@/views/BuildingDevelopment/index.vue";
import ExistingBuildingView from "@/views/ExistingBuilding/index.vue";
import IoTView from "@/views/IoT/index.vue";
import HardwareView from "@/views/Hardware/index.vue";
import SoftwareView from "@/views/Software/index.vue";
import MeterView from "@/views/Meter/index.vue";
import NotFound from "@/views/404.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Home - Wove Technologies' },
    // component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    component: HomeView
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    meta: { title: 'Contact Us - Wove Technologies' },
    component: ContactView
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: 'About Us - Wove Technologies' },
    component: AboutView
  },
  {
    path: '/infrastructure',
    name: 'TransportationView',
    meta: { title: 'Transportation - Wove Technologies' },
    component: TransportationView
  },
  {
    path: '/buildingDevelopment',
    name: 'BuildingDevelopment',
    meta: { title: 'Government and Enterprise Office - Wove Technologies' },
    component: BuildingDevelopmentView
  },

  {
    path: '/governmentAndEnterpriseOffice',
    name: 'governmentAndEnterpriseOffice',
    meta: { title: 'Government and Enterprise Office - Wove Technologies' },
    component: BuildingDevelopmentView
  },
  {
    path: '/education',
    name: 'Education',
    meta: { title: 'Education - Wove Technologies' },
    component: BuildingDevelopmentView
  },
  {
    path: '/retail',
    name: 'Retail',
    meta: { title: 'Retail - Wove Technologies' },
    component: BuildingDevelopmentView
  },
  {
    path: '/healthcare',
    name: 'Healthcare',
    meta: { title: 'Healthcare - Wove Technologies' },
    component: BuildingDevelopmentView
  },
  {
    path: '/hospitality',
    name: 'Hospitality',
    meta: { title: 'Hospitality - Wove Technologies' },
    component: BuildingDevelopmentView
  },
  //================================================
  {
    path: '/existingBuilding',
    name: 'ExistingBuilding',
    meta: { title: 'Existing Building - Wove Technologies' },
    component: ExistingBuildingView
  },

  {
    path: '/BuildingEquipmentAndFacilitiesOperation',
    name: 'BuildingEquipmentAndFacilitiesOperation',
    meta: { title: 'Building Equipment and Facilities Operation - Wove Technologies' },
    component: ExistingBuildingView
  },
  {
    path: '/IntegratedOperationsAndManagementPlatform',
    name: 'IntegratedOperationsAndManagementPlatform',
    meta: { title: 'Integrated Operations and Management Platform - Wove Technologies' },
    component: ExistingBuildingView
  },
  {
    path: '/SmartPropertyManagementPlatform',
    name: 'SmartPropertyManagementPlatform',
    meta: { title: 'Smart Property Management Platform - Wove Technologies' },
    component: ExistingBuildingView
  },
  {
    path: '/SmartDetentionCenterManagementPlatform',
    name: 'SmartDetentionCenterManagementPlatform',
    meta: { title: 'Smart Detention Center Management Platform - Wove Technologies' },
    component: ExistingBuildingView
  },


  //================================================
  {
    path: '/iot',
    name: 'IoT',
    meta: { title: 'IoT - Wove Technologies' },
    component: IoTView
  },
  {
    path: '/hardware',
    name: 'Hardware',
    meta: { title: 'Hardware - Wove Technologies' },
    component: HardwareView
  },
  {
    path: '/software',
    name: 'Software',
    meta: { title: 'Software - Wove Technologies' },
    component: SoftwareView
  },
  {
    path: '/meter',
    name: 'Meter',
    meta: { title: 'Intelligent Watt-Hour Meter - Wove Technologies' },
    component: MeterView
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    meta: { title: 'Not Found' },
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 滚动到顶部
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title as string
  // if (location.pathname !== '/') {
  //   location.href = location.origin + '/#' + location.pathname
  // }
  next()
})

export default router

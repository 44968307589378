<template>
    <div class="hardware-scroll-block">
        <div v-for="(info, index) of infoList" class="hardware-scroll-item">
            <img :src="require(`../../assets/Hardware_TV_img_${index + 1}.webp`)" class="hardware-scroll-block-img">
            <div class="hardware-scroll-block-name">{{ info.name }}wq</div>
            <ul>
                <li v-for="text of info.infos" class="hardware-scroll-block-info">
                    {{ text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name: "TVList",
    data() {
        return {
            infoList: [
                {
                    index: 1,
                    name: 'Philips OLED 55OLED808/12',
                    infos: [
                        '4K Ambilight TV',
                        'P5 AI perfect picture engine'
                    ]
                },
                {
                    index: 2,
                    name: 'Philips OLED+ 65OLED908/12',
                    infos: [
                        '4K Ambilight TV',
                        'Sound by Bowers & Wilkins'
                    ]
                },
                {
                    index: 3,
                    name: 'Philips The Xtra 65PML9008/12',
                    infos: [
                        '4K Ambilight TV',
                        'Dolby Atmos sound'
                    ]
                },
                {
                    index: 4,
                    name: 'Philips The One 43PUS8508/12',
                    infos: [
                        '4K Ambilight TV',
                        'P5 Perfect Picture Engine'
                    ]
                },
                {
                    index: 5,
                    name: 'Philips 65PUS8309/12',
                    infos: [
                        'LED 4K Ambilight TV',
                        'Titan OS smart platform'
                    ]
                },
                {
                    index: 6,
                    name: 'Philips 65OLED708/12',
                    infos: [
                        'OLED 4K Ambilight TV',
                        'Dolby Atmos sound'
                    ]
                },
                {
                    index: 7,
                    name: 'Philips 75PUS7354/12',
                    infos: [
                        '4K UHD LED Android TV',
                        '1700 Picture Performance Index'
                    ]
                },
                {
                    index: 8,
                    name: 'Philips 75PUS8505/12',
                    infos: [
                        '4K UHD LED Android TV',
                        'Performance Series with Ambilight'
                    ]
                },
                {
                    index: 9,
                    name: 'Philips 65OLED935/12',
                    infos: [
                        'OLED+ with P5 AI dual picture engine',
                        'Sound by Bowers & Wilkins'
                    ]
                },
                {
                    index: 10,
                    name: 'Philips 65OLED805/12',
                    infos: [
                        '4K UHD OLED Android TV',
                        'P5 AI perfect picture engine'
                    ]
                },
                {
                    index: 11,
                    name: 'AOC U6285',
                    infos: [
                        'Ultra HD',
                        'Dolby Audio'
                    ]
                },
            ],
        }
    },
})
</script>

<style lang="scss" scoped></style>
<template>
    <div class="contact-form">
        <el-form
            ref="contactForm"
            :model="formData"
            :rules="rules"
            label-position="top"
            v-loading="loading"
        >
            <div class="contact-form-item">
                <el-form-item label="First Name" prop="firstName">
                    <el-input v-model="formData.firstName" class="contact-form-input" />
                </el-form-item>
            </div>
            <div class="contact-form-item">
                <el-form-item label="Last Name" prop="lastName">
                    <el-input v-model="formData.lastName" class="contact-form-input" />
                </el-form-item>
            </div>
            <div class="contact-form-item">
                <el-form-item label="E-mail" prop="email">
                    <el-input v-model="formData.email" class="contact-form-input" />
                </el-form-item>
            </div>
            <div class="contact-form-item">
                <el-form-item label="Telephone" prop="telephone">
                    <el-input v-model="formData.telephone" class="contact-form-input" />
                </el-form-item>
            </div>
            <div class="tMgt16 tMgl20 tMgr20">
                <el-form-item label="Message" prop="message">
                    <el-input type="textarea" :rows="5" v-model="formData.message" class="contact-form-input contact-form-textarea" />
                </el-form-item>
            </div>

            <div
                class="contact-form-button"
                @click="submit()"
            >Send</div>
        </el-form>

        <div style="clear: both"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useReCaptcha } from "vue-recaptcha-v3";
import { ElMessage } from 'element-plus'
import axios from "axios";
export default defineComponent ({
    name: "ContactForm",
    data() {
        return {
            formData: {
                firstName: "",
                lastName: "",
                email: "",
                telephone: "",
                message: "",
            },
            rules: {
                firstName: { required: true, message: 'Please input First Name', trigger: 'blur' },
                lastName: { required: true, message: 'Please input Last Name', trigger: 'blur' },
                email: { required: true, message: 'Please input E-mail', trigger: 'blur' },
                message: { required: true, message: 'Please input Message', trigger: 'blur' },
            },
            loading: false,
        }
    },
    methods: {
        async recaptcha() {
            const recaptcha1: any = useReCaptcha();
            // console.log("验证码正在验证。。。")
            await recaptcha1?.recaptchaLoaded()
            const token = await recaptcha1?.executeRecaptcha('login')
            // console.log(token)
            return token;
        },
        async submit() {
            let formEl: any = this.$refs.contactForm;
            if (!formEl) return;
            await formEl.validate(async (valid: boolean, fields: any) => {
                let submitToken = await this.recaptcha();
                // console.log('submit', submitToken);
                if (valid) {
                    // send start
                    this.loading = true
                    await axios({
                        method: 'post',
                        url: '/api/contact',
                        // url: 'http://localhost:8000/api/contact',
                        data: {
                            ...this.formData,
                            token: submitToken
                        }
                    }).then((res) => {
                        // console.log('res====', res);
                        ElMessage({
                            message: 'Thank you for the inquiry!',
                            type: 'success',
                        })
                    }).catch((err) => {
                        console.log('err====', err);
                        ElMessage({
                            message: err.message,
                            type: 'error',
                        })
                    }).finally(() => { this.loading = false; })
                } else {
                    // console.log('error submit!', fields)
                }
            })
        },
    },
})
</script>

<style lang="scss" scoped>
.contact-form {
    text-align: left;

    .contact-form-key {
        font-size: 14px;
        line-height: 30px;
        font-weight: 300;
    }
    .contact-form-input {
        height: 72px;
        margin-top: 16px;
    }
    .contact-form-textarea {
        height: auto;
    }
    .contact-form-item {
        display: inline-block;
        width: calc(50% - 40px);
        margin: 16px 20px 0;
    }
    .contact-form-button {
        display: block;
        float: right;
        width: 217px;
        height: 48px;
        margin-top: 48px;
        border-radius: 27px;
        background-color: #002E72;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 48px;
        letter-spacing: .03em;
        user-select: none;
        cursor: pointer;
        transition: all .2s ease;
    }
    .contact-form-button:active {
        filter: grayscale(.5);
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        //
    }
    @media screen and (max-width: 767px) {
        .contact-form-input {
            height: 60px;
            margin-top: 0;
        }
        .contact-form-textarea {
            height: auto;
        }
        .contact-form-item {
            width: calc(100% - 40px);
        }
        .contact-form-button {
            float: none;
            margin: 45px auto 0;
            //width: 310px;
            width: calc(100% - 40px);
            height: 64px;
            line-height: 64px;
            border-radius: 32px;
        }
    }
}
</style>
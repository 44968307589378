<template>
  <div id="home" class="home">
      <div class="home-header">
          <div class="home-header-mask"></div>
          <div class="home-header-title">Weave Your Vision</div>
          <div class="home-header-text tMgt16 tMgb16">
              At Wove Technologies, we deliver customized solutions,
              including software and hardware, all designed to empower<br class="home-header-text-br"/>
              your business and drive it toward success.
          </div>
          <ContactUsButton class="home-header-button" color="black" :routeInfo="{ path:'/about' }" />
      </div>
<!--          <el-carousel-->
<!--              class="home-carousel"-->
<!--              id="carousel-wrap"-->
<!--              direction="vertical"-->
<!--              ref="carouse"-->
<!--              :autoplay="false"-->
<!--          >-->
<!--              <el-carousel-item class="home-carousel-item-container img-NewBuildingSolutions">-->
<!--                  <div class="home-carousel-item home-carousel-updown-left">-->
<!--                      <div class="home-carousel-item-title">New Building <br class="home-carousel-item-title-br"/>Solutions</div>-->
<!--                      <div class="home-carousel-item-text">-->
<!--                          Infuse intelligence from the ground up with our smart technologies.-->
<!--                          We specialize in crafting <br class="home-carousel-item-text-br" />tech-savvy spaces,-->
<!--                          guaranteeing your new building boasts the latest in software,-->
<!--                          hardware, and infrastructure for a modern and efficient environment.-->
<!--                      </div>-->
<!--                      <ContactUsButton text="Learn More" color="blue" class="home-carousel-item-contact" :routeInfo="{ path:'/buildingDevelopment' }" />-->
<!--                  </div>-->
<!--                  <div class="home-carousel-item home-carousel-updown-right img-NewBuildingSolutions"></div>-->
<!--              </el-carousel-item>-->
<!--              <el-carousel-item class="home-carousel-item-container img-ExistingBuildingUpgrades">-->
<!--                  <div class="home-carousel-item home-carousel-updown-left">-->
<!--                      <div class="home-carousel-item-title">Existing Building <br class="home-carousel-item-title-br"/>Upgrades</div>-->
<!--                      <div class="home-carousel-item-text">-->
<!--                          Our solutions seamlessly integrate with your current setup,-->
<!--                          providing a cost-effective way to enhance efficiency, security, and connectivity.-->
<!--                          <br/>-->
<!--                          <br/>-->
<!--                          Transform your existing building into a smart,-->
<!--                          responsive environment that meets the demands of contemporary living and working.-->
<!--                      </div>-->
<!--                      <ContactUsButton text="Learn More" color="blue" class="home-carousel-item-contact" :routeInfo="{ path:'/existingBuilding' }" />-->
<!--                  </div>-->
<!--                  <div class="home-carousel-item home-carousel-updown-right img-ExistingBuildingUpgrades"></div>-->
<!--              </el-carousel-item>-->
<!--              <el-carousel-item class="home-carousel-item-container img-Infrastructure">-->
<!--                  <div class="home-carousel-item home-carousel-updown-left">-->
<!--                      <div class="home-carousel-item-title">Infrastructure</div>-->
<!--                      <div class="home-carousel-item-text">-->
<!--                          We specialize in creating infrastructures that-->
<!--                          seamlessly integrate with your existing systems,-->
<!--                          maximizing efficiency while minimizing disruptions.-->
<!--                          <br/>-->
<!--                          <br/>-->
<!--                          Whether upgrading or integrating new technologies,-->
<!--                          our solutions ensure a connected and agile environment.-->
<!--                      </div>-->
<!--                      <ContactUsButton text="Learn More" color="blue" class="home-carousel-item-contact" :routeInfo="{ path:'/infrastructure' }" />-->
<!--                  </div>-->
<!--                  <div class="home-carousel-item home-carousel-updown-right img-Infrastructure"></div>-->
<!--              </el-carousel-item>-->
<!--          </el-carousel>-->
      <HomeCarousel />

      <div class="home-industries">
          <div class="home-industries-container">
              <div class="home-industries-title">Industries we work with</div>
              <ScrollBlock
                  scrollId="scroll1"
                  :blockWidth="blockWidth"
                  :itemNum="6"
                  :canScroll="blockWidth !== 540"
                  :showButton="blockWidth === 540"
              >
                  <template v-slot:content>
                      <a class="home-industries-item pointer" href="/infrastructure">
                          <img src="../assets/home_transportation.webp" class="home-industries-img">
                          <div class="home-industries-text">Transportation</div>
                      </a>
                      <a class="home-industries-item pointer" href="/governmentAndEnterpriseOffice">
                          <img src="../assets/home_Government.webp" class="home-industries-img">
                          <div class="home-industries-text">Government and <br/>Enterprise Office</div>
                      </a>
                      <a class="home-industries-item pointer" href="/retail">
                          <img src="../assets/home_Retail.webp" class="home-industries-img">
                          <div class="home-industries-text">Retail</div>
                      </a>
                      <a class="home-industries-item pointer" href="/education">
                          <img src="../assets/home_Education.webp" class="home-industries-img">
                          <div class="home-industries-text">Education</div>
                      </a>
                      <a class="home-industries-item pointer" href="/healthcare">
                          <img src="../assets/home_Healthcare.webp" class="home-industries-img">
                          <div class="home-industries-text">Healthcare</div>
                      </a>
                      <a class="home-industries-item pointer" href="/hospitality">
                          <img src="../assets/home_Hospitality.webp" class="home-industries-img">
                          <div class="home-industries-text">Hospitality</div>
                      </a>
                  </template>
              </ScrollBlock>
          </div>
      </div>

      <div class="home-partner">
          <div class="home-partner-container">
              <div class="home-partner-title">Partner With A Reliable <br class="home-partner-title-br" />Technology Company</div>
              <div class="home-partner-text">
                  Reach out for tailored solutions to elevate your operations.
              </div>
          </div>

          <ContactUsButton color="black" class="home-partner-button" :routeInfo="{ path:'/contactUs' }" />
      </div>

      <div class="home-chooseWove">
          <div class="home-chooseWove-title">Why Choose Wove</div>
          <div class="home-chooseWove-container">
              <div class="home-chooseWove-item-box">
                  <HomeChooseCard
                      gifKey="home_gif_1"
                      :src="require('../assets/gif/Why Choose wove - gif 1.webm')"
                      :videoOptions="{
                          playbackRate: 1.5,
                          startTime: 0.7,
                          pauseTime: 1.7,
                          restartTime: 3.3
                      }"
                      title="Holistic Integration"
                      text="Seamlessly blend hardware and software for comprehensive solutions."
                  />
                  <HomeChooseCard
                      gifKey="home_gif_2"
                      :src="require('../assets/gif/Why Choose wove - gif 2.webm')"
                      :videoOptions="{
                          playbackRate: 1.5,
                          startTime: 0.3,
                          pauseTime: 0.7,
                          restartTime: 2.5
                      }"
                      title="Future-Ready Connectivity"
                      text="Stay ahead with cutting-edge solutions shaping the future."
                  />
                  <div class="home-chooseWove-item">
                      <img src="../assets/Holistic%20Integration_BG.webp" class="home-chooseWove-img-bg">
<!--                      <img src="../assets/Holistic%20Integration.webp" class="home-chooseWove-img">-->
                      <img src="../assets/gif/Why%20Choose%20wove%20-%20gif%201.webp" class="home-chooseWove-img">
                  </div>
                  <div class="home-chooseWove-item">
                      <img src="../assets/Holistic%20Integration_BG.webp" class="home-chooseWove-img-bg">
<!--                      <img src="../assets/Future-Ready%20Connectivity.webp" class="home-chooseWove-img">-->
                      <img src="../assets/gif/Why%20Choose%20wove%20-%20gif%202.webp" class="home-chooseWove-img">
                  </div>
              </div>
              <div class="home-chooseWove-item-box">
                  <HomeChooseCard
                      gifKey="home_gif_3"
                      :src="require('../assets/gif/Why Choose wove - gif 3.webm')"
                      :videoOptions="{
                          playbackRate: 1.3,
                          startTime: 0.1,
                          pauseTime: 1.3,
                          restartTime: 1.8,
                      }"
                      title="Efficiency Redefined"
                      text="Streamlined processes for enhanced performance and productivity."
                  />
                  <HomeChooseCard
                      gifKey="home_gif_4"
                      :src="require('../assets/gif/Why Choose wove - gif 4.webm')"
                      :videoOptions="{
                          playbackRate: 1.3,
                          startTime: 0.35,
                          pauseTime: 1.4,
                          restartTime: 2.35
                      }"
                      title="Client-Centric Approach"
                      text="Tailored services to meet your unique needs and goals."
                  />
                  <div class="home-chooseWove-item">
                      <img src="../assets/Holistic%20Integration_BG.webp" class="home-chooseWove-img-bg">
<!--                      <img src="../assets/Holistic%20Integration.webp" class="home-chooseWove-img">-->
                      <img src="../assets/gif/Why%20Choose%20wove%20-%20gif%203.webp" class="home-chooseWove-img">                  </div>
                  <div class="home-chooseWove-item">
                      <img src="../assets/Holistic%20Integration_BG.webp" class="home-chooseWove-img-bg">
<!--                      <img src="../assets/Holistic%20Integration.webp" class="home-chooseWove-img">-->
                      <img src="../assets/gif/Why%20Choose%20wove%20-%20gif%204.webp" class="home-chooseWove-img">                  </div>
              </div>
          </div>
      </div>

      <a class="home-SoftwareSolutions" href="/software">
          <div class="home-SoftwareSolutions-img-backcolor"></div>
          <div class="home-SoftwareSolutions-img"></div>
          <div class="home-SoftwareSolutions-title">Software Solutions ></div>
          <div class="home-SoftwareSolutions-text">
              From intelligent data analytics to user-friendly management platforms,
              explore a range of solutions designed to transform your digital landscape.
          </div>
      </a>
      <div class="home-SoftwareSolutions home-SoftwareSolutions-hardware">
          <a class="home-SoftwareSolutions-box" href="/hardware">
              <div class="home-HardwareInnovations-img"></div>
              <div class="home-SoftwareSolutions-box-title">Hardware Innovations ></div>
              <div class="home-SoftwareSolutions-box-text">
                  Professional Display, Energy Management System Architecture,
                  Industrial Grade IoT Edge Computer, and more.
              </div>
          </a>
          <a class="home-SoftwareSolutions-box colorBlack" href="/meter">
              <div class="home-IntelligentWattHourMeter-img"></div>
              <div class="home-SoftwareSolutions-box-title">Intelligent Watt-Hour Meter ></div>
              <div class="home-SoftwareSolutions-box-text">
                  A smart, accurate, and stable solution designed to
                  meet your energy management needs with our Power Meters.
              </div>
          </a>
      </div>
      <a class="home-SoftwareSolutions" href="/iot">
          <div class="home-SoftwareSolutions-img home-IoT-img"></div>
          <div class="home-SoftwareSolutions-title home-IoT-title">IoT ></div>
          <div class="home-SoftwareSolutions-text home-IoT-text">
              Advanced technologies like IoT, LoRaWAN® Gateways,
              and various sensors for an intelligent and efficient ecosystem.
          </div>
      </a>

      <div class="home-CustomizedOfferings">
          <div class="home-CustomizedOfferings-title">Customized Offerings</div>
          <div class="home-CustomizedOfferings-text">
              Share your vision, and we'll turn it into reality by providing
              tailored solutions to meet your specific requirements.
          </div>
          <ContactForm />
      </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactUsButton from "@/components/contactUsButton.vue";
import ContactForm from "@/components/contactForm.vue";
import ScrollBlock from '@/components/scrollBlock.vue';
import HomeCarousel from '@/views/components/HomeCarousel.vue';
import HomeChooseCard from "@/views/components/HomeChooseCard.vue";

export default defineComponent({
    name: 'HomeView',
    components: {
        ScrollBlock,
        ContactUsButton,
        ContactForm,
        HomeCarousel,
        HomeChooseCard,
    },
    data() {
        return {
            industriesIndex: 1,
            blockWidth: 540,
        }
    },
    mounted() {
        // this.slideBanner();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // desktop,tablet : 379    mobile: 290
            if (windowWidth > 767) {
                this.blockWidth = 540
            } else {
                this.blockWidth = 294
            }
        },
        linkTo(url: any, query: any = {}) {
            // let toUrl = typeof url === 'string' ? url : '/';
            // this.$router.push({ path: toUrl, query: query });
        },
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
        handleIndustriesButtonClick(direction: any) {
            // home-industries-item-box 点击按钮滚动
            let curX = - (this.industriesIndex - 1) * 520;
            if (direction === 'left' && this.industriesIndex > 1) {
                --this.industriesIndex;
            } else if (direction === 'right' && this.industriesIndex < 6) {
                this.industriesIndex = this.industriesIndex + 1;
            } else { return }
            let index = 0;
            let industriesBox: any = document.getElementById('home-industries-item-box');
            let move = setInterval(() => {
                if (direction === 'left') {
                    curX += 10
                    industriesBox.style.transform = `translate(${curX}px, 0px)`
                } else {
                    curX -=  10
                    industriesBox.style.transform = `translate(${curX}px, 0px)`
                }
                index++;
                if (index === 52) { clearInterval(move) }
            }, 1)
        },
        slideBanner() {
            // 将carousel组件改成支持触屏滑动
            const box = document.querySelector('#carousel-wrap') as Element
            const that: any = this
            let startPoint = 0
            let stopPoint = 0
            const resetPoint = () => {
                startPoint = 0
                stopPoint = 0
            }
            // 手指按下
            box.addEventListener('touchstart', (e: any) => {
                // that.autoplay = false
                // 记录起始坐标值
                // startPoint = e.changedTouches[0].pageX
                startPoint = e.changedTouches[0].pageY
            })
            // 手指滑动
            box.addEventListener('touchmove', (e: any) => {
                // 记录结束坐标这
                // stopPoint = e.changedTouches[0].pageX
                stopPoint = e.changedTouches[0].pageY
            })
            // 手指抬起,判断滑向上一页还是下一页
            box.addEventListener('touchend', () => {
                // 手指抬起开始自动播放
                // that.autoplay = true
                // 不移动
                if (stopPoint === 0 || startPoint - stopPoint === 0) {
                    resetPoint()
                    return
                }
                // 上一页
                if (startPoint - stopPoint > 0) {
                    resetPoint()
                    that.$refs.carouse.next()
                    return
                }
                // 下一页
                if (startPoint - stopPoint < 0) {
                    resetPoint()
                    that.$refs.carouse.prev()
                    return
                }
            })
        }
    }
});
</script>

<style lang="scss" src="./style/home.scss" scoped />

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/Arrow_white.webp'
import _imports_1 from '../assets/Arrow_black.webp'


const _withScopeId = n => (_pushScopeId("data-v-a0fcb1d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "content-arrow"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  class: "content-arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([_ctx.classNames, {
            'contact-button': true,
            'active': _ctx.active,
            'text-white': _ctx.color === 'white',
            'text-black': _ctx.color === 'black',
            'background-blue': _ctx.color === 'blue',
            'background-black': _ctx.color === 'black',
        }]),
    onMouseover: _cache[0] || (_cache[0] = () => { this.active = true; }),
    onMouseleave: _cache[1] || (_cache[1] = () => { this.active = false; }),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    href: _ctx.routeInfo.path
  }, [
    _createTextVNode(_toDisplayString(_ctx.text || 'Contact Us') + " ", 1),
    (_ctx.color === 'white'|| 'blue')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.color === 'black')
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}
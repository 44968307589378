<template>
    <div class="common-main software-main">
        <div class="common-background-top transportation-background-top backImgStyle">
            <div class="common-title">
                Software
            </div>
        </div>

        <div class="software-img-block backImgStyle">
            <div class="software-img-block-content">
                <div class="software-img-block-title color-0af">Energy Panel Module</div>
                <div class="software-img-block-text color-0af">
                    The Energy Data Query enables quick access to energy data based
                    on usage and location, enhanced through visualizations. The Energy
                    Panel Module provides a powerful overview of annual, monthly, and
                    daily device trends, offering dynamic chart options for diverse analyses.
                    It also facilitates a global view of total energy consumption
                    for comprehensive cross-system analysis.
                </div>
            </div>
        </div>

        <div class="common-block-left-right common-flex-reverse existing-part-padding-160">
            <div class="common-block-left-right-info">
                <div class="common-block-left-info-container">
                    <div class="common-block-left-right-title">
                        Geographic Information Module
                    </div>
                    <div class="common-block-left-right-text">
                        Embedded GIS (Geographic Information System), with unique visual effects and geographic analysis capabilities, can effectively query project information and distribution geographically, playing a role in facilitating rational management. The one-click map switching function facilitates quick access to the desired page.
                    </div>
                </div>
            </div>
            <img src="../../assets/Software_img_1.webp" class="software-block-img software-block-img-geographic">
        </div>

        <el-carousel
            class="software-carousel"
            id="carousel-wrap"
            :direction="carouselMode"
            ref="carouse"
            :autoplay="false"
            @change="handleCarouselChange"
        >
            <div :class="{
                'backImgStyle': true,
                'software-carousel-bg-style': true,
                'software-carousel-bg-img-1': true,
                'software-carousel-bg-active': this.curCarouselIndex === 0
            }"></div>
            <div :class="{
                'backImgStyle': true,
                'software-carousel-bg-style': true,
                'software-carousel-bg-img-2': true,
                'software-carousel-bg-active': this.curCarouselIndex === 1
            }"></div>
            <el-carousel-item class="software-carousel-info-container">
                <div class="software-carousel-info">
                    <div class="software-carousel-info-title">
                        Real Time Data Monitoring
                    </div>
                    <div class="software-carousel-info-text">
                        The Real-Time Data Monitoring feature provides a real-time display of the last collected data on gauges. In the event of abnormal data, the system, through its detection mechanism, annotates and conspicuously alerts the user. Each data point supports the retrieval of historical data curves for a recent period, facilitating decision analysis. The tabulated data sections are customizable, allowing users to select and include the specific data points they want to monitor closely.
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item class="software-carousel-info-container">
                <div class="software-carousel-info">
                    <div class="software-carousel-info-title">
                        Meter Architecture Management
                    </div>
                    <div class="software-carousel-info-text">
                        The gauges support various types of tag-based definitions used for sub-metering calculations, billing functions, energy analysis, data statistics, etc. The flexible customization allows users to define and configure according to their specific needs.
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="common-block-left-right common-flex-reverse existing-part-padding-160">
            <div class="common-block-left-right-info">
                <div class="common-block-left-info-container">
                    <div class="common-block-left-right-title">
                        Rule Engine Module
                    </div>
                    <div class="common-block-left-right-text">
                        The system provides a visual rule engine that utilizes visual rule chain technology and the Actor programming model. It enables users to customize conditions, filter and dynamically process telemetry data or system events. The engine can dynamically define the processing logic for complex data or events, offering functions such as data correction, data validation, alarm setting, comprehensive calculations, cross-system configuration control, and data exchange with external systems.
                    </div>
                </div>
            </div>
            <img src="../../assets/Software_img_2.webp" class="software-block-img">
        </div>

        <div class="software-img-block software-img-block-chart backImgStyle">
            <div class="software-img-block-content">
                <div class="software-img-block-title">
                    Chart Library Module
                </div>
                <div class="software-img-block-text">
                    The system provides dozens of different styles of chart libraries, and all the components are "open source." Users can choose the charts they want to present based on their preferences, representing the distribution and presentation of data. In addition, users with IT capabilities can edit chart components online using JavaScript language. The system also supports the direct import of external JSON-format chart components, making it flexible and easy to use.
                </div>
            </div>
        </div>

        <div class="common-block-left-right common-flex-reverse existing-part-padding-160">
            <div class="common-block-left-right-info">
                <div class="common-block-left-info-container">
                    <div class="common-block-left-right-title">
                        3D Spatial Data
                    </div>
                    <div class="common-block-left-right-text">
                        The 3D Spatial Data feature offers a dynamic demonstration effect, displaying real-time crucial data based on regional distribution. Users can freely switch between data to view the dynamic values they need most, providing an immediate understanding of the usage status of various systems. The dynamic display in 3D format significantly reduces digital visual fatigue, contributing to a more user-friendly experience in system usage and management.
                    </div>
                </div>
            </div>
            <img src="../../assets/Software_img_3.webp" class="software-block-img">
        </div>

        <div class="software-img-block software-img-block-intelligent backImgStyle">
            <div class="software-img-block-content">
                <div class="software-img-block-title color-0af">
                    Intelligent Data Analysis
                </div>
                <div class="software-img-block-text color-0af">
                    Smart data analysis is the core function of the energy system. This function aims to assist users in intelligent data comparison, identify efficient and inefficient points, analyze inefficient areas, and hand them over to the automated control system for execution. This forms a complete closed-loop application of data acquisition, analysis, and control, helping users achieve energy-saving management and improve the quality of building energy consumption.
                </div>
            </div>
        </div>

        <div class="common-block-left-right common-flex-reverse existing-part-padding-160">
            <div class="common-block-left-right-info">
                <div class="common-block-left-info-container">
                    <div class="common-block-left-right-title">
                        Alarm Monitoring Module
                    </div>
                    <div class="common-block-left-right-text">
                        The Real-Time Data Monitoring module provides real-time displays of the communication status of gauges and the abnormal status of gauge data points. The module incorporates various algorithm models for determining the status information points, accurately capturing data point states. This includes indications of gauge offline, gauge malfunction, abnormal values in voltage/current/frequency/power/electricity, and reporting faults to the user. It supports notifications through SMS, email, voice, and other methods.
                    </div>
                </div>
            </div>
            <img src="../../assets/Software_img_4.webp" class="software-block-img">
        </div>

        <div class="existing-margin-bottom-200"></div>

        <div class="common-bottom-contact-container">
            <div class=" software-bottom-bg backImgStyle" />
            <div class="common-bottom-contact-title">
                Customized Hardware Offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br/>
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactUsButton from '@/components/contactUsButton.vue';
export default defineComponent ({
    name: "Software",
    components: {
        ContactUsButton,
    },
    data() {
        return {
            curCarouselIndex: 0,
            carouselMode: 'vertical',
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleCarouselChange(index) {
            this.curCarouselIndex = index;
        },
        handleResize() {
            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // desktop,tablet : 379    mobile: 290
            if (windowWidth > 767) {
                this.carouselMode = 'vertical'
            } else {
                this.carouselMode = 'horizontal'
            }
        },
        toContactView() {
            this.$router.push({path: '/contactUs'});
        },
    }
})
</script>

<style lang="scss" src="../style/common.scss" scoped />
<style lang="scss" src="../style/software.scss" scoped />
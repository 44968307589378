import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/index.scss'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

createApp(App).use(store).use(ElementPlus).use(router).use(Viewer)
    // .use(VueReCaptcha,{
    //     siteKey:'6Lc8TKgpAAAAAIW7VPOZ_RDJzHDsOHnlmxoAuJD4',
    //     loaderOptions:{
    //         useRecaptchaNet: true,
    //         autoHideBadge: false
    //     }})
    .mount('#app')

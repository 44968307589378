<template>
    <div class="contact-us">
        <div class="contact-us-head">
            <div class="contact-us-head-title">Contact Us</div>
            <div class="contact-us-head-info">
                <div class="contact-us-head-info-box">
                    <div class="contact-us-head-info-title">Hours</div>
                    <div class="contact-us-head-info-text">9:00 am - 5:00 pm</div>
                </div>
                <div class="contact-us-head-info-box">
                    <div class="contact-us-head-info-title">Email</div>
                    <div class="contact-us-head-info-text">contact@wovetech.co</div>
                </div>
<!--                <div class="contact-us-head-info-box">-->
<!--                    <div class="contact-us-head-info-title">Phone</div>-->
<!--                    <div class="contact-us-head-info-text">647 111 1111</div>-->
<!--                </div>-->
                <div class="contact-us-head-info-box">
                    <div class="contact-us-head-info-title">Address</div>
                    <div class="contact-us-head-info-text">
                        151 Yonge st, suite 1137 Toronto,<br/>
                        ON, M5C 2W7. Canada
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-us-body">
            <ContactForm />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactForm from "@/components/contactForm.vue";
export default defineComponent({
    name: "ContactView",
    components: {
        ContactForm
    },
    data() {
        return {
            //
        }
    },
    methods: {
        //
    }
})
</script>

<style lang="scss" src="./style/contact.scss" scoped />
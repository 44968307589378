<template>
    <div class="common-main">
        <div class="common-page-content">
            <div class="common-sub-title">
                Transformative Innovations Redefine The Hotel Experience
            </div>

            <div class="common-part">
                <div class="common-part-title">
                    Smart Controls Redefine the Hotel Experience
                </div>
                <div class="common-part-content">
                    With upgraded safety features, efficient energy management, and a focus on guest satisfaction, hotels can enhance their overall operational efficiency, leading to increased profits and more bookings, creating a win-win scenario for both guests and hotel management.
                </div>
            </div>

            <div class="common-block-container">
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">01</div>
                        <div class="common-info-box-title">
                            Service Information
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Timely access to service information enables hotel departments to understand guest needs promptly.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">02</div>
                        <div class="common-info-box-title">
                            Sensor Control
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Use various sensors and limit sensors to generate logical signals for the rational control of electrical devices.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">03</div>
                        <div class="common-info-box-title">
                            Network System
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Manage network information for convenient remote monitoring and access to hotel management.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">04</div>
                        <div class="common-info-box-title">
                            Safety Control
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Prioritize guest safety through a secure interface. Implement card-based power access for energy efficiency and considerate property safety. Monitoring SOS signals reflects the hotel's meticulous care for guests.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">05</div>
                        <div class="common-info-box-title">
                            Temperature Control
                        </div>
                    </div>
                    <div class="common-info-box-text">
                        Manage and control the air conditioning system for comfort and energy efficiency.
                    </div>
                </div>
            </div>

            <div class="common-block-left-right">
                <img src="../../assets/hospitality_img_1.webp" class="common-block-img-desktop">
                <img src="../../assets/hospitality_img_1.webp" class="common-block-img-tablet">
                <img src="../../assets/hospitality_img_1.webp" class="common-block-img-mobile">
                <div class="common-block-left-right-info">
                    <div class="common-block-right-info-container">
                        <div class="common-block-left-right-title">
                            Lighting Control
                        </div>
                        <div class="common-block-left-right-text">
                            Create different scenes and a cozy atmosphere through light adjustment and switch control.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-block-left-right common-flex-reverse">
                <div class="common-block-left-right-info">
                    <div class="common-block-left-info-container">
                        <div class="common-block-left-right-title">
                            Motorized Curtains
                        </div>
                        <div class="common-block-left-right-text">
                            Conveniently operate curtains to enhance the guest experience.
                        </div>
                    </div>
                </div>
                <img src="../../assets/hospitality_img_2.webp" class="common-block-img">
            </div>

            <div class="common-part tMgb160-responsive">
                <div class="common-part-title">
                    Redefine Hotel Operations for Increased Revenue
                </div>
                <div class="common-part-content">
                    Discover how smart controls in hotels aren't just about guest comfort; they're a strategic business tool. From energy savings to personalized services, these innovations optimize operations, elevate guest experiences, and, most importantly, contribute to increased revenue for savvy hoteliers.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container hospitality-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br />
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import ContactUsButton from '@/components/contactUsButton.vue'

export default defineComponent({
    name: "Hospitality",
    components: {
        ContactUsButton,
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="../style/buildingDevelopment.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
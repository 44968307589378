<template>
    <div class="notfound-main">
        <div class="notfound-404">404</div>
        <div class="font-Headline-5">Page not found</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
    name: "NotFound"
})
</script>

<style lang="scss" scoped>
.notfound-main {
    min-height: calc(100vh - 635px);
    background-color: #fff;
    padding: 1px 0 0;

    .notfound-404 {
        margin: 50px auto  0;
        font-size: 100px;
        line-height: 100px;
    }
    .font-Headline-5 {
        color: #a5a5a5;
    }
}

</style>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "woveApp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_BackgroundImage = _resolveComponent("BackgroundImage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header, { style: {"z-index":"2"} }),
    _createVNode(_component_BackgroundImage),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_main, { style: {"padding":"0","margin-top":"80px","position":"relative","z-index":"1","overflow":"hidden"} }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Footer)
  ]))
}
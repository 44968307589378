import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "hardware-scroll-block" }
const _hoisted_2 = { class: "hardware-scroll-item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "hardware-scroll-block-name" }
const _hoisted_5 = { class: "hardware-scroll-block-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoList, (info, index) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("img", {
          src: require(`../../assets/Hardware_TV_img_${index + 1}.webp`),
          class: "hardware-scroll-block-img"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, _toDisplayString(info.name) + "wq", 1),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(info.infos, (text) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString(text), 1))
          }), 256))
        ])
      ]))
    }), 256))
  ]))
}
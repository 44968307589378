<template>
    <a
        :class="[classNames, {
            'contact-button': true,
            'active': active,
            'text-white': color === 'white',
            'text-black': color === 'black',
            'background-blue': color === 'blue',
            'background-black': color === 'black',
        }]"
        @mouseover="() => { this.active = true; }"
        @mouseleave="() => { this.active = false; }"
        @click="handleClick"
        :href="routeInfo.path"
    >
        {{ text || 'Contact Us' }}
        <img v-if="color === 'white'|| 'blue'" src="../assets/Arrow_white.webp"  class="content-arrow">
        <img v-if="color === 'black'" src="../assets/Arrow_black.webp"  class="content-arrow">
    </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    name: "ContactUsButton",
    props: {
        text: String,
        // handleClick: Function,
        classNames: {
            default: '',
            type: String,
        },
        routeInfo: {
            default: { path: '/', query: {} },
            tpe: Object
        },
        color: {
            default: 'white',
            type: String
        }
    },
    data() {
        return {
            active: false,
        }
    },
    methods: {
        handleClick() {
            this.$emit('linkTo', this.routeInfo.path, this.routeInfo.query)
        },
    }
})
</script>

<style lang="scss" scoped>
.contact-button {
    display: flex;
    align-items: center;
    position: relative;
    width: 138px;
    height: 48px;
    border: 2px solid #fff;
    border-radius: 27px;
    font-size: 16px;
    text-align: left;
    text-decoration: none;
    font-weight: 500;
    color: #fff;
    padding: 12px 24px;
    transition: width 0.2s;
    user-select: none;
    cursor: pointer;
    letter-spacing: normal;

    &.background-blue {
        background-color: #0047AB;
        border: none;
    }
    &.background-black {
        background-color: #ffff;
        border: none;
    }
}
.text-white {
    color: #fff;
}
.text-black {
    color: #000;
}
.content-arrow {
    position: absolute;
    left: 125px;
    top: 17px;
    height: 13.65px;
    width: 7.55px;
    opacity: 0;
    transition: opacity 0.2s;
}
.active {
    .content-arrow {
        opacity: 1;
    }
    &.contact-button {
        width: 162px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .active {
        .content-arrow { opacity: 0 }
        &.contact-button { width: 138px; }
    }
}
@media screen and (max-width: 767px) {
    .contact-button {
        width: 306px;
        text-align: center;
        justify-content: center;
    }
    .active {
        .content-arrow { opacity: 0 }
        &.contact-button { width: 306px; }
    }
}
</style>

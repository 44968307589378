<template>
    <div
        :class="{
            'scroll-block-main': true,
            'scroll-x': canScroll,
        }"
        @mouseenter="handleEnter"
        @mouseleave="handleLeave"
    >
        <div class="scroll-block-content" :id="scrollId" style="transform: translate(0, 0);">
            <slot name="content"></slot>
        </div>
    </div>
    <div v-if="showButton" class="scroll-block-button-box">
        <img src="../assets/button_left.webp"
             class="scroll-block-button pointer"
             @click="handleIndustriesButtonClick('left')">
        <img
            src="../assets/button_right.webp"
            class="scroll-block-button pointer tMgl30"
            @click="handleIndustriesButtonClick('right')">
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent ({
    name: "ScrollBlock",
    props: {
        blockWidth: {
            type: Number,
            default: 0
        },
        itemNum: {
            type: Number,
            default: 1
        },
        scrollId: {
            type: String,
            default: 'scrollId'
        },
        canScroll: {
            type: Boolean,
            default: false
        },
        showButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            industriesIndex: 1,
            scrollInterval: null as any,
        }
    },
    methods: {
        handleEnter() {
            // console.log("Enter");
            let industriesBox: any = document.getElementById(this.scrollId);
            let transformValue = industriesBox.style.getPropertyValue('transform');
            let match = transformValue.match(/translate\(([^,]+),/);
            let curX = match ? parseFloat(match[1]) : 0;

            industriesBox.style.transition = 'all .1s linear'

            // 决定方向的变量
            let directionVariable = curX === 0 ? -1 : 1;

            if (this.scrollInterval) clearInterval(this.scrollInterval);
            this.scrollInterval = setInterval(() => {
                if (curX === 0 || curX === -540 * (this.itemNum - 1)) {
                    // 滚到最后一格或第一格后换方向
                    directionVariable = directionVariable * -1
                }

                curX -= 10 * directionVariable;
                // console.log('curX=========', curX);
                industriesBox.style.transform = `translate(${curX}px, 0px)`

                // 当前位置
                this.industriesIndex = Math.floor(-curX/540) + 1;

            }, 100)
        },
        handleLeave() {
            // console.log("leave");
            if (this.scrollInterval) clearInterval(this.scrollInterval);
            let industriesBox: any = document.getElementById(this.scrollId);
            industriesBox.style.transition = 'all .2s'
        },
        handleIndustriesButtonClick(direction: any) {
            let scrollWidth = this.blockWidth;
            let elementId = this.scrollId;
            if (direction === 'left' && this.industriesIndex > 1) {
                --this.industriesIndex;
            } else if (direction === 'right' && this.industriesIndex < this.itemNum) {
                this.industriesIndex = this.industriesIndex + 1;
            } else { return }
            let curX = - (this.industriesIndex - 1) * scrollWidth;
            let industriesBox: any = document.getElementById(elementId);
            // console.log('curX===', curX)
            industriesBox.style.transform = `translate(${curX}px, 0px)`
        },
    }
})
</script>

<style lang="scss" scoped>
.scroll-block-main {
    overflow-x: hidden;
    margin-top: 24px;
}
.scroll-x {
    overflow-x: scroll;
}
.scroll-block-content {
    //overflow-x: hidden;
    white-space: nowrap;
    transition: all .2s;
}
.scroll-block-button-box {
    margin-top: 26px;
    text-align: left;
    .scroll-block-button {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        transition: background-color 0.2s ease;
    }
    .scroll-block-button:hover {
        background-color: rgba(0, 0, 0, .15)
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .scroll-block-button-box {
        padding-left: 60px;
    }
}

</style>
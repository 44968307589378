<template>
    <div class="about-us">
        <div class="backImgStyle about-us-head">
            <div class="about-us-head-info">
                <div class="about-us-head-text">Build.</div>
                <div class="about-us-head-text">Solve.</div>
                <div class="about-us-head-text">Evolve.</div>
            </div>
        </div>

        <div class="about-us-mission">
            <div class="about-us-title-style about-us-mission-title">Our Mission</div>
            <div class="about-us-mission-info">
                Wove Technologies is on a mission to Build, Solve, and Evolve.
                We strive to empower businesses and communities through cutting-edge
                hardware and software solutions, fostering a smarter, more interconnected world.
            </div>
        </div>

        <div class="backImgStyle about-us-standards">
            <div class="about-us-title-style about-us-standards-text">
                Zero <span class="about-us-title-style" style="color: #a7ff98">Carbon</span> Building Standards
            </div>
        </div>

        <div class="about-us-solution">
            <div class="about-us-title-style about-us-solution-title">
                A made-in-Canada solution
            </div>
            <div class="about-us-solution-text">
                Our zero-carbon building prioritizes energy efficiency and
                minimizes greenhouse gas emissions from both building materials
                and operations. To offset any remaining emissions until complete
                elimination is feasible, we employ high-quality carbon offsets as a counterbalance.
            </div>
        </div>

        <div class="about-us-solution-commitment">
            <div class="about-us-title-style about-us-solution-commitment-title">
                Commitment to sustainability
            </div>
            <div class="about-us-solution-info">
                <div class="about-us-solution-info-box">
                    <img src="../assets/AboutUs_CAGBC_mobile.webp" class="about-us-solution-info-GACBC">
                    <div class="about-us-solution-info-title">Canada Green Building Council</div>
                    <div class="about-us-solution-info-text">
                        As a partner of the Canada Green Building Council,
                        Wove actively collaborates with the organization to
                        advance eco-friendly practices and elevate construction
                        standards in Canada. Together, we endeavor to forge smarter,
                        greener, and more sustainable built environments for the future.
                    </div>
                </div>
                <div class="about-us-solution-info-box">
                    <img src="../assets/AboutUsCommitmentIcon.webp" class="about-us-solution-info-Icon">
                    <div class="about-us-solution-info-title">
                        Leadership In Energy And Environmental Design
                    </div>
                    <div class="about-us-solution-info-text">
                        Wove Technologies, a pioneer in sustainable and intelligent
                        building solutions, is committed to advancing Leadership
                        in Energy and Environmental Design (LEED) standards.
                        Through a dedication to environmentally conscious technologies,
                        Wove integrates innovative solutions to champion green building initiatives.
                        Our customized offerings align with LEED criteria, fostering energy efficiency,
                        minimizing environmental impact, and promoting overall sustainability.
                    </div>
                </div>
            </div>
        </div>

        <div class="about-us-pathways">
            <div class="about-us-title-style about-us-pathways-title">
                Two pathways for buildings
            </div>
            <div class="about-us-pathways-info">
                <div class="about-us-pathways-info-box">
                    <div class="about-us-pathways-info-box-header">
                        <div class="about-us-pathways-info-box-title-little">01</div>
                        <div class="about-us-pathways-info-box-title">Design Standard</div>
                    </div>
                    <div class="about-us-pathways-info-box-text">
                        The ZCB-Design Standard™ guides the design of new buildings
                        or retrofits of existing ones. It offers a pathway to ensure
                        buildings can achieve zero-carbon once in operation.
                    </div>
                </div>
                <div class="about-us-pathways-info-box">
                    <div class="about-us-pathways-info-box-header">
                        <div class="about-us-pathways-info-box-title-little">02</div>
                        <div class="about-us-pathways-info-box-title">Performance Standard</div>
                    </div>
                    <div class="about-us-pathways-info-box-text">
                        The ZCB-Performance Standard™ is used to demonstrate that a
                        building has achieved zero-carbon operations. It requires
                        verification annually.
                    </div>
                </div>
            </div>
        </div>

        <div class="about-us-vision">
            <div class="about-us-title-style about-us-vision-title">
                Our Vision
            </div>
            <div class="about-us-vision-info" id="about-us-vision-info">
                <div class="about-us-vision-box" id="about-us-vision-box-1">
<!--                    <img src="../assets/Contact_Weave.webp" class="about-us-vision-info-title">-->
                    <div class="about-us-vision-info-title">
                        <div class="about-us-vision-box-title">
                            <img src="../assets/Contact_icon.webp" class="about-us-vision-icon" />
                            <span class="about-us-vision-box-title-first">W</span>eave
                        </div>
                    </div>
                    <div class="about-us-vision-info-line"></div>
                    <div class="about-us-vision-info-text">
                        Weaving connections through active collaboration and networking,
                        we meticulously craft a tapestry where innovation flourishes,
                        ensuring everyone finds their place in the fabric of success.
                    </div>
                </div>
                <div class="about-us-vision-box" id="about-us-vision-box-2">
<!--                    <img src="../assets/Contact_Opportunities.webp" class="about-us-vision-info-title">-->
                    <div class="about-us-vision-info-title">
                        <div class="about-us-vision-box-title">
                            <img src="../assets/Contact_icon.webp" class="about-us-vision-icon" />
                            <span class="about-us-vision-box-title-first">O</span>pportunities
                        </div>
                    </div>
                    <div class="about-us-vision-info-line"></div>
                    <div class="about-us-vision-info-text">
                        Opportunity serves as our catalyst for growth and development,
                        where we actively create and seize doors that open to new horizons,
                        contributing to a brighter future for all.
                    </div>
                </div>
                <div class="about-us-vision-box" id="about-us-vision-box-3">
<!--                    <img src="../assets/Contact_Vision.webp" class="about-us-vision-info-title">-->
                    <div class="about-us-vision-info-title">
                        <div class="about-us-vision-box-title">
                            <img src="../assets/Contact_icon.webp" class="about-us-vision-icon" />
                            <span class="about-us-vision-box-title-first">V</span>ision
                        </div>
                    </div>
                    <div class="about-us-vision-info-line"></div>
                    <div class="about-us-vision-info-text">
                        Vision guiding us towards progress, fostering a forward-looking perspective.
                        We inspire innovation and envision a future where technology
                        seamlessly integrates with everyday life, turning dreams into reality.
                    </div>
                </div>
                <div class="about-us-vision-box" id="about-us-vision-box-4">
<!--                    <img src="../assets/Contact_Empowerment.webp" class="about-us-vision-info-title">-->
                    <div class="about-us-vision-info-title">
                        <div class="about-us-vision-box-title">
                            <img src="../assets/Contact_icon.webp" class="about-us-vision-icon" />
                            <span class="about-us-vision-box-title-first">E</span>mpowerment
                        </div>
                    </div>
                    <div class="about-us-vision-info-line"></div>
                    <div class="about-us-vision-info-text">
                        Empowerment of change, we provide individuals with the essential tools,
                        knowledge, and resources. This fosters self-confidence, resilience,
                        and leadership, resulting in a meaningful impact and sustained growth.
                    </div>
                </div>
            </div>
        </div>

        <div class="about-us-pathways about-us-help">
            <div class="about-us-title-style about-us-pathways-title">
                We can help you with ...
            </div>
            <div class="about-us-pathways-info">
                <div class="about-us-pathways-info-box">
                    <div class="about-us-pathways-info-box-header">
                        <div class="about-us-pathways-info-box-title-little">01</div>
                        <div class="about-us-pathways-info-box-title">New Construction Service</div>
                    </div>
                    <div class="about-us-pathways-info-box-text">
                        Wove's New Construction Service specializes in delivering innovative
                        solutions for newly built structures and projects. Whether it's designing
                        smart and sustainable buildings, implementing cutting-edge technologies,
                        or optimizing energy efficiency, Wove ensures that new constructions
                        adhere to the highest standards of modernization.
                    </div>
                </div>
                <div class="about-us-pathways-info-box">
                    <div class="about-us-pathways-info-box-header">
                        <div class="about-us-pathways-info-box-title-little">02</div>
                        <div class="about-us-pathways-info-box-title">Existing Construction Advancement Service</div>
                    </div>
                    <div class="about-us-pathways-info-box-text">
                        Wove's Existing Construction Advancement Service is dedicated to enhancing
                        and upgrading pre-existing structures. This involves seamlessly integrating
                        IoT and edge computing solutions into established buildings, elevating them
                        to be smarter, more efficient, and technologically advanced.
                        Wove addresses the unique challenges of retrofitting to bring existing
                        constructions into the digital age.
                    </div>
                </div>

                <div class="about-us-pathways-info-box">
                    <div class="about-us-pathways-info-box-header">
                        <div class="about-us-pathways-info-box-title-little">03</div>
                        <div class="about-us-pathways-info-box-title">Infrastructure Advancement Service</div>
                    </div>
                    <div class="about-us-pathways-info-box-text">
                        Wove's Infrastructure Advancement Service centers on the foundational
                        elements that sustain communities and organizations. This encompasses
                        devising intelligent solutions for public utilities, transportation systems,
                        and other critical infrastructure components. Wove's expertise guarantees
                        that the infrastructure is not only robust but also equipped with the latest
                        technological advancements for optimal performance.
                    </div>
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container about-us-info-bottom">
            <div class="common-bottom-contact-title">
                Join&nbsp;Us&nbsp;in Shaping&nbsp;the&nbsp;Future
            </div>
            <div class="common-bottom-contact-text">
                Wove Technologies invites you to be part of a journey where innovation meets connectivity.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactUsButton from "@/components/contactUsButton.vue";
export default defineComponent({
    name: "AboutUs",
    components: {
        ContactUsButton,
    },
    data() {
        return {
          //
        }
    },
    mounted() {
        window.addEventListener("scroll", this.visionInfoAnimation);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.visionInfoAnimation);
    },
    methods: {
        visionInfoAnimation() {
            let iBox: any = document.getElementById("about-us-vision-info");
            let vBox_1: any = document.getElementById("about-us-vision-box-1");
            let vBox_2: any = document.getElementById("about-us-vision-box-2");
            let vBox_3: any = document.getElementById("about-us-vision-box-3");
            let vBox_4: any = document.getElementById("about-us-vision-box-4");

            let curBottomHeight = window.scrollY + window.innerHeight;
            let showHeight = iBox.offsetTop;
            if (curBottomHeight >= showHeight) {
                // 窗口位置出现/超过 Our Vision，展示动画
                vBox_1.classList.add('animate__fadeInUp')
                setTimeout(() =>{
                    vBox_2.classList.add('animate__fadeInUp')
                }, 250);
                setTimeout(() =>{
                    vBox_2.classList.add('animate__fadeInUp')
                }, 500);
                setTimeout(() =>{
                    vBox_3.classList.add('animate__fadeInUp')
                }, 750);
                setTimeout(() =>{
                    vBox_4.classList.add('animate__fadeInUp')
                }, 1000);
            } else {
                vBox_1.classList.remove('animate__fadeInUp')
                vBox_2.classList.remove('animate__fadeInUp')
                vBox_3.classList.remove('animate__fadeInUp')
                vBox_4.classList.remove('animate__fadeInUp')
            }
        },
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="./style/about.scss" scoped />
<style lang="scss" src="./style/animation.scss" scoped />
<style lang="scss" src="./style/common.scss" scoped />

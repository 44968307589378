<template>
    <div class="common-main building-main">
        <div :class="{
            'common-background-top': true,
            'transportation-background-top': true,
            'backImgStyle': true,
            'background-government': activeName === '/buildingDevelopment' || activeName === '/governmentAndEnterpriseOffice',
            'background-education': activeName === '/education',
            'background-retail': activeName === '/retail',
            'background-healthcare': activeName === '/healthcare',
            'background-hospitality': activeName === '/hospitality',
        }">
            <div class="common-title">
                {{ curTitle }}
            </div>
            <div class="common-tabs-container">
                <el-tabs v-model="activeName" class="iot-tabs" @tab-change="handleTabChange">
                    <el-tab-pane label="Government and Enterprise OfficeGateway" name="/governmentAndEnterpriseOffice">
                        <template #label>
                            <div :class="{ 'common-tab': true, 'common-tab-mobile-85': true, 'active': activeName === '/buildingDevelopment' || activeName === '/governmentAndEnterpriseOffice'}">
                                Government and Enterprise Office
                            </div>
                        </template>
                    </el-tab-pane>

                    <el-tab-pane label="Education" name="/education">
                        <template #label>
                            <div :class="{ 'common-tab': true, 'active': activeName === '/education'}">
                                Education
                            </div>
                        </template>
                    </el-tab-pane>

                    <el-tab-pane label="Retail" name="/retail">
                        <template #label>
                            <div :class="{ 'common-tab': true, 'active': activeName === '/retail'}">
                                Retail
                            </div>
                        </template>
                    </el-tab-pane>

                    <el-tab-pane label="Healthcare" name="/healthcare">
                        <template #label>
                            <div :class="{ 'common-tab': true, 'active': activeName === '/healthcare'}">
                                Healthcare
                            </div>
                        </template>
                    </el-tab-pane>

                    <el-tab-pane label="Hospitality" name="/hospitality">
                        <template #label>
                            <div :class="{ 'common-tab': true, 'active': activeName === '/hospitality'}">
                                Hospitality
                            </div>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="building-content">
            <GovernmentAndEnterpriseOffice v-if="activeName === '/buildingDevelopment' || activeName === '/governmentAndEnterpriseOffice'" />
            <Education v-if="activeName === '/education'" />
            <Retail v-if="activeName === '/retail'" />
            <Healthcare v-if="activeName === '/healthcare'" />
            <Hospitality v-if="activeName === '/hospitality'" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import GovernmentAndEnterpriseOffice from "./GovernmentAndEnterpriseOffice";
import Education from "./Education"
import Retail from "./Retail"
import Healthcare from "./Healthcare"
import Hospitality from "./Hospitality"
export default defineComponent({
    name: "BuildingDevelopment",
    components: {
        GovernmentAndEnterpriseOffice,
        Education,
        Retail,
        Healthcare,
        Hospitality
    },
    data() {
        return {
            curTitle: 'Government and Enterprise Office',
            titleList: [
                { title: 'Government and Enterprise Office', name: '/governmentAndEnterpriseOffice' },
                { title: 'Education', name: '/education' },
                { title: 'Retail', name: '/retail' },
                { title: 'Healthcare', name: '/healthcare' },
                { title: 'Hospitality', name: '/hospitality' },
            ],
            activeName: '/buildingDevelopment'
        }
    },
    created() {},
    mounted() {
        // this.handleTabChange(this.$route.query.index || '1')
        // console.log('this.$route', this.$route)
        this.activeName = this.$route?.path || '/governmentAndEnterpriseOffice';
        this.setTitle();
    },
    methods: {
        handleTabChange(tabName) {
            // console.log('tabName', tabName);
            // this.activeName = tabName;
            window.history.pushState({current: tabName}, tabName, tabName);
            this.setTitle();
        },
        setTitle() {
            let curTitleObj = this.titleList.find(item => item.name === this.activeName);
            if (!curTitleObj) return;
            this.curTitle = curTitleObj.title;
            document.title = this.curTitle;
        },
    }
})
</script>

<style lang="scss" src="../style/buildingDevelopment.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
<template>
    <div class="common-main meter-main">
        <div class="common-background-top transportation-background-top backImgStyle">
            <div class="common-title">
                Intelligent Watt-Hour Meter
            </div>
        </div>

        <div class="meter-smart-solutions backImgStyle">
            <div class="meter-smart-solutions-title font-Headline-3">
                Smart Solutions For Energy Management
            </div>
            <div class="meter-smart-solutions-text font-body-1">
                Discover the excellence of our Power Meter – a smart, accurate, and stable solution designed to meet your energy management needs.
            </div>
            <div class="meter-smart-solutions-bg-1 backImgStyle"></div>
            <div class="meter-smart-solutions-bg-2 backImgStyle"></div>
<!--            <div class="meter-smart-solutions-img-container">-->
<!--                <img src="../../assets/Meter_SmartSolutions_img_1.webp" class="meter-smart-solutions-img">-->
<!--                <img src="../../assets/Meter_SmartSolutions_img_2.webp" class="meter-smart-solutions-img">-->
<!--                <img src="../../assets/Meter_SmartSolutions_img_3.webp" class="meter-smart-solutions-img">-->
<!--                <img src="../../assets/Meter_SmartSolutions_img_4.webp" class="meter-smart-solutions-img">-->
<!--            </div>-->
        </div>

        <div class="meter-power-meter">
            <div class="meter-power-meter-title font-Headline-2">
                Multifunction Power Meter
            </div>
            <div class="meter-power-meter-items">
                <div class="meter-power-meter-item">
                    <img src="../../assets/Meter_icon_1.webp" class="meter-power-meter-icon">
                    <div class="meter-power-meter-info">
                        <div class="meter-power-meter-info-title font-Headline-4">
                            Easy Installation
                        </div>
                        <div class="meter-power-meter-text font-body-2">
                            Standard specifications and convenient installation for ease of use
                        </div>
                    </div>
                </div>
                <div class="meter-power-meter-item">
                    <img src="../../assets/Meter_icon_2.webp" class="meter-power-meter-icon">
                    <div class="meter-power-meter-info">
                        <div class="meter-power-meter-info-title font-Headline-4">
                            Monitoring Parameters
                        </div>
                        <div class="meter-power-meter-text font-body-2">
                            Monitoring parameters include voltage, current, power, power factor, and more
                        </div>
                    </div>
                </div>
                <div class="meter-power-meter-item">
                    <img src="../../assets/Meter_icon_3.webp" class="meter-power-meter-icon">
                    <div class="meter-power-meter-info">
                        <div class="meter-power-meter-info-title font-Headline-4">
                            Modernizations
                        </div>
                        <div class="meter-power-meter-text font-body-2">
                            Step-by-step realization of Informatization, Digitization, Automation, and Interactivity
                        </div>
                    </div>
                </div>
                <div class="meter-power-meter-item">
                    <img src="../../assets/Meter_icon_4.webp" class="meter-power-meter-icon">
                    <div class="meter-power-meter-info">
                        <div class="meter-power-meter-info-title font-Headline-4">
                            Standard Interfaces
                        </div>
                        <div class="meter-power-meter-text font-body-2">
                            Unified communication protocols and interfaces
                        </div>
                    </div>
                </div>
                <div class="meter-power-meter-item">
                    <img src="../../assets/Meter_icon_5.webp" class="meter-power-meter-icon">
                    <div class="meter-power-meter-info">
                        <div class="meter-power-meter-info-title font-Headline-4">
                            Data Logging
                        </div>
                        <div class="meter-power-meter-text font-body-2">
                            Powerful data logging and storage capabilities for comprehensive information collection throughout all periods
                        </div>
                    </div>
                </div>
                <div class="meter-power-meter-item">
                    <img src="../../assets/Meter_icon_6.webp" class="meter-power-meter-icon">
                    <div class="meter-power-meter-info">
                        <div class="meter-power-meter-info-title font-Headline-4">
                            Electricity Usage Records
                        </div>
                        <div class="meter-power-meter-text font-body-2">
                            Provides twelve months of historical electricity usage records, guiding users in planning and controlling electricity expenses, maximizing cost savings.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="common-block-container existing-part-padding-160">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Smart
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>Historical record function</li>
                        <li>Power outage meter reading function </li>
                        <li>Multi-rate metering functionality</li>
                        <li>Management function</li>
                        <li>Energy freeze function</li>
                        <li>Prepaid function </li>
                        <li>Scheduled power-off function</li>
                        <li>Malicious load identification function</li>
                        <li>Alarm function</li>
                        <li>Tiered pricing for electricity function</li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Accurate
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            High precision, low power consumption
                        </li>
                        <li>
                            Multi-rate metering
                        </li>
                        <li>
                            Supports forward and reverse energy metering
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Stable
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Sophisticated design, high metering accuracy
                        </li>
                        <li>
                            Anti-strong magnetic, anti-tampering
                        </li>
                        <li>
                            Special large-scale integrated circuit as the core device
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="meter-features">
            <div class="meter-features-title font-Headline-3">Features</div>
            <div class="meter-features-items">
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Rectangle%20889.webp" class="meter-features-icon">
<!--                    <div class="meter-features-item-title font-Headline-6"></div>-->
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        LCD display with 7+1 digits backlight showing active/reactive energy (9999999.9kWh), Multi-rate electric power, three-phase voltage, current, active power, power factor, apparent power, frequency, load threshold, payment mode, and other information.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20188.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports RS485 communication, Modbus protocol.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20239.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports passive pulse output.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20189.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Parameter setting through buttons.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20174.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Historical energy statistics and daily energy freezing function.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20208.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Has a disconnection function for power cutoff in case of insufficient balance.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20193.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Offers Multi-rate Metering with Multiple Billing Modes.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20207.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Malicious load identification.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20241.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports Multi-rate Energy Metering Functionality.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20242.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports prepaid/postpaid settings.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20183.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Real-time clock functionality.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20182.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        Easy Installation
                    </div>
                    <div class="meter-features-item-text font-body-2">
                        35mm DIN standard rail installation.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20184.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        Accurate Measurement
                    </div>
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Measures active energy with 0.5 or 1 class accuracy.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20227.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        Power-Backup Metering
                    </div>
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Built-in battery, allows meter reading during power outage.
                    </div>
                </div>
                <div class="meter-features-item meter-features-item-no-height"></div>
            </div>
        </div>

        <div class="meter-advanced-multifunction backImgStyle">
<!--            <div class="meter-advanced-multifunction-items">-->
<!--                <img src="../../assets/Meter_AdvancedMultifunction_img_1.webp" class="meter-advanced-multifunction-img">-->
<!--                <img src="../../assets/Meter_AdvancedMultifunction_img_2.webp" class="meter-advanced-multifunction-img">-->
<!--            </div>-->
        </div>

        <div style="position: relative; padding-top: 1px">
            <div class="meter-advanced-multifunction-bg"></div>
            <div class="common-part existing-part-padding-160">
                <div class="meter-advanced-multifunction-title font-Headline-3">
                    Advanced Multifunction Power Meter For Comprehensive Electrical Monitoring
                </div>
                <div class="meter-advanced-multifunction-text font-body-1">
                    Our multifunction power meter displays rich information, with comprehensive electrical quantity management and display. It includes features such as dynamic warning, status of digital input and output, SOE event recording, Multi-rate metering functionality, circuit load rate display, and harmonic monitoring. The instrument's tool-free installation design makes it suitable for various industries, including switchgear, distribution cabinets, and circuit distribution control.
                </div>
            </div>
        </div>

        <div class="common-block-container existing-part-padding-160">
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">01</div>
                    <div class="common-info-box-title">
                        Smart
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>Total energy management function</li>
                        <li>Supports SOE event recording.</li>
                        <li>Multi-rate metering functionality</li>
                        <li>Circuit load rate display.</li>
                        <li>Harmonic monitoring.</li>
                        <li>Supports dual RS485 communication.</li>
                        <li>Energy pulse output.</li>
                        <li>Remote control or out-of-limit alarm status output.</li>
                        <li>Supports switch signal input.</li>
                        <li>Supports 4-20mA output.</li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">02</div>
                    <div class="common-info-box-title">
                        Accurate
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Supports forward and reverse energy metering
                        </li>
                        <li>
                            Total Energy Management Function
                        </li>
                        <li>
                            Energy metering accuracy of 0.5S class.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common-info-box">
                <div class="common-info-box-header">
                    <div class="common-info-box-title-little">03</div>
                    <div class="common-info-box-title">
                        Stable
                    </div>
                </div>
                <div class="common-info-box-text">
                    <ul>
                        <li>
                            Tool-free installation design suitable for various switchgear distribution control applications.
                        </li>
                        <li>
                            Modular design for flexible functionality expansion.
                        </li>
                        <li>
                            Specialized large-scale integrated circuits as core components.
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="meter-features">
            <div class="meter-features-title font-Headline-3">Features</div>
            <div class="meter-features-items">
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20239%20(1).webp" class="meter-features-icon">
                    <img src="../../assets/meter_icon/Group%20239%20(1).webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports total energy management function and display.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20241.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports functions such as energy metering, multi-rate metering, circuit load rate, harmonics, SOE event recording, etc.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/8%20DI.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Up to 8 channels of DI passive digital input for monitoring
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/4%20DO.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        up to 4 channels of DO digital output for remote control or out-of-limit alarms.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20188.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports up to two RS485 interfaces, meeting the needs of data monitoring for the backstage system, and supports Modbus protocol.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/PLC.webp" class="meter-features-icon">
                    <div class="meter-features-item-text font-body-2 meter-features-item-text-no-title">
                        Supports 1-3 channels of 4~20mA transmission output, facilitating integration with DCS and PLC systems.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20215.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        AC/DC Power
                    </div>
                    <div class="meter-features-item-text font-body-2">
                        Instrument power supply supports AC/DC 85-265V, suitable for international use.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20184.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        Accurate Measurement
                    </div>
                    <div class="meter-features-item-text font-body-2">
                        Energy metering accuracy of 0.5S class.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20186.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        Rich Parameters
                    </div>
                    <div class="meter-features-item-text font-body-2">
                        Backlit LCD display, showcasing a wealth of electrical parameters.
                    </div>
                </div>
                <div class="meter-features-item">
                    <img src="../../assets/meter_icon/Group%20182.webp" class="meter-features-icon">
                    <div class="meter-features-item-title font-Headline-6">
                        Easy Installation
                    </div>
                    <div class="meter-features-item-text font-body-2">
                        Compact 80mmx80mm embedded installation, convenient and space-saving.
                    </div>
                </div>
                <div class="meter-features-item meter-features-item-no-height"></div>
            </div>
        </div>

        <div class="meter-solutions">
            <div class="meter-solutions-title font-Headline-3">
                Efficient Energy Monitoring Solutions
            </div>
            <div class="meter-solutions-text font-body-1">
                Wove Technologies brings you cutting-edge technology that combines precision with ease of installation, ensuring a seamless experience in monitoring and managing electrical consumption. Explore the future of energy management with our innovative and reliable meter series, where intelligence meets efficiency.
            </div>
        </div>

        <div class="common-bottom-contact-container">
            <div class="meter_bottom_bg backImgStyle" />
            <div class="common-bottom-contact-title">
                Customized Hardware Offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br/>
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactUsButton from '@/components/contactUsButton.vue';
export default defineComponent ({
    name: "Meter",
    components: {
        ContactUsButton,
    },
    data() {
        return {
            curCarouselIndex: 0,
            carouselMode: 'vertical',
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleCarouselChange(index) {
            this.curCarouselIndex = index;
        },
        handleResize() {
            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            // desktop,tablet : 379    mobile: 290
            if (windowWidth > 767) {
                this.carouselMode = 'vertical'
            } else {
                this.carouselMode = 'horizontal'
            }
        },
        toContactView() {
            this.$router.push({path: '/contactUs'});
        },
    }
})
</script>

<style lang="scss" src="../style/common.scss" scoped />
<style lang="scss" src="../style/meter.scss" scoped />
<template>
    <div class="common-main transportation-main">
        <div class="common-background-top transportation-background-top backImgStyle">
            <div class="common-title">
                Infrastructure
            </div>
        </div>
        <div class="common-page-content">
            <div class="common-sub-title">Tailored Solutions For The Future Of Mobility</div>
            <img src="../../assets/Infrastructure_sub_title_img.webp" class="common-sub-title-img common-sub-title-img-desktop">
            <img src="../../assets/Infrastructure_sub_title_img_mobile.webp" class="common-sub-title-img common-sub-title-img-mobile">

            <div class="common-part">
                <div class="common-part-title">Revolutionizing Transportation</div>
                <div class="common-part-content">
                    Wove Technologies stands at the forefront of transforming the transportation
                    landscape with cutting-edge innovations. Our smart solutions are meticulously
                    designed to enhance efficiency, safety, and sustainability in the ever-evolving
                    realm of transportation. Through seamless integration of advanced technologies,
                    we aim to redefine the future of how people and goods move.
                </div>
            </div>

            <div class="common-block-container">
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">01</div>
                        <div class="common-info-box-title">Enhancing Transportation Management</div>
                    </div>
                    <div class="common-info-box-text">
                        Rely on our Dependable Content Delivery solution. Leverage the power of
                        Android for live information and seamless integration with custom apps. In
                        case of a primary source outage, FailOver ensures backup transport
                        information is displayed reliably.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">02</div>
                        <div class="common-info-box-title">Multi-touch Capability<br/><br/></div>
                    </div>
                    <div class="common-info-box-text">
                        Deliver an unforgettable interactive encounter with the ability to handle up
                        to 10 touchpoints simultaneously. Ideal for both collaborative and competitive
                        applications, this display seamlessly engages your audience with diverse content,
                        making it a perfect fit for education, public spaces, corporate environments,
                        hospitality, and retail settings. The multi-touch feature includes automatic
                        touch recognition and a HID-compliant USB connector, ensuring effortless
                        plug-and-play operation.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">03</div>
                        <div class="common-info-box-title">Dependable Content Delivery</div>
                    </div>
                    <div class="common-info-box-text">
                        Real-time Information Integration with Custom Apps and Reliable Backup Display
                        during Primary Source Outage with Fail Over Technology, enhancing Transportation
                        Management.
                    </div>
                </div>
                <div class="common-info-box">
                    <div class="common-info-box-header">
                        <div class="common-info-box-title-little">04</div>
                        <div class="common-info-box-title">Energy-efficient</div>
                    </div>
                    <div class="common-info-box-text">
                        Low-consumption SmartPower Technology Philips Airfield Display System products
                        can control and preset backlight intensity. While ensuring excellent display
                        performance with high brightness, it significantly reduces energy consumption,
                        thereby greatly lowering operational costs for businesses.
                    </div>
                </div>
            </div>

            <div class="common-block-left-right">
                <img src="../../assets/transportation_img_1.webp" class="common-block-img-desktop">
                <img src="../../assets/transportation_img_1_tablet.webp" class="common-block-img-tablet">
                <img src="../../assets/transportation_img_1.webp" class="common-block-img-mobile">
                <div class="common-block-left-right-info">
                    <div class="common-block-right-info-container">
                        <div class="common-block-left-right-title">
                            24/7 All-Day Operation Designed For The
                            Unique Demands Of Airport Environments
                        </div>
                        <div class="common-block-left-right-text">
                            Our Philips products are tailored for 24/7 continuous use. Utilizing
                            high-quality components ensures higher reliability, allowing complete
                            dependence on this series for round-the-clock performance.24/7 Make
                            quick and easy updates to your arrivals and departures scheduling,
                            as well as important announcements using CMND.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-block-left-right common-flex-reverse">
                <div class="common-block-left-right-info">
                    <div class="common-block-left-info-container">
                        <div class="common-block-left-right-title">
                            Display Quality
                        </div>
                        <div class="common-block-left-right-text">
                            Clear Presentation with Full HD LED Technology LED displays achieve
                            better backlight brightness control, resulting in high contrast.
                            This creates a uniformly bright screen, offering clearer visuals and
                            more vibrant colors.
                        </div>
                    </div>
                </div>
                <img src="../../assets/transportation_img_2.webp" class="common-block-img">
            </div>

            <div class="common-part tMgb160-responsive">
                <div class="common-part-title">
                    Wove Technologies' Intelligent Solutions for Safer and Efficient Journeys
                </div>
                <div class="common-part-content">
                    In the dynamic field of transportation, Wove Technologies introduces a
                    paradigm shift. From intelligent traffic management systems to innovative
                    vehicle connectivity solutions, our technologies pave the way for safer,
                    more efficient journeys. Embracing sustainability and reliability, Wove's
                    impact extends beyond the roads, contributing to a smarter and more
                    connected transportation ecosystem.
                </div>
            </div>
        </div>

        <div class="common-bottom-contact-container transportation-bottom-bg">
            <div class="common-bottom-contact-title">
                Customized offerings
            </div>
            <div class="common-bottom-contact-text">
                At Wove, we customize solutions to meet your needs.<br class="transportation-bottom-br"/>
                Contact us for more information on enhancing your operations.
            </div>
            <ContactUsButton color="black" class="common-bottom-contact-button" :routeInfo="{ path:'/contactUs' }" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactUsButton from '@/components/contactUsButton.vue'
export default defineComponent({
    name: "TransportationView",
    components: {
        ContactUsButton,
    },
    data() {
        return {}
    },
    methods: {
        toContactView() {
            this.$router.push({path: '/contactUs'})
        },
    }
})
</script>

<style lang="scss" src="../style/transportation.scss" scoped />
<style lang="scss" src="../style/common.scss" scoped />
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3049861b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-card-title font-Headline-4" }
const _hoisted_2 = ["id", "src"]
const _hoisted_3 = { class: "home-card-text font-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "home-card-container",
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("video", {
      id: _ctx.gifKey,
      class: "home-card-gif",
      src: _ctx.src,
      muted: "",
      controls: false,
      disablepictureinpicture: "",
      onLoadedmetadata: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.videoLoadedmetadata && _ctx.videoLoadedmetadata(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ], 32))
}